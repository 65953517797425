import { WritingPiece, KS2Data, Class, Student, StylusCriteriaScore, Strand, Topic, Level, KS2Criteria, StylusCriteria, StudentWritingPiece, Scan } from "../types";
import { stylus_criteria } from "./ks2-data";

const classes: Class[] = [
    { id: "21", name: "Class 10" },
    { id: "22", name: "Class 11" },
    { id: "23", name: "Class 12" },
];

const students: Student[] = [
    {id: "recTq8N4mk7EpuOcM", name: "Isla M", classId: "21"},
{id: "recbE1Xg5cBDf7bnP", name: "Mia A", classId: "22"},
{id: "recIfDi176HvbTpCQ", name: "Diya P", classId: "21"},
{id: "recYnFNaO0S0jNKDY", name: "Declan A", classId: "21"},
{id: "recNRiZpL0EM398jO", name: "Anaiya A", classId: "23"},
{id: "recftCWT5pqtAtM5S", name: "Joshua U", classId: "23"},
{id: "recuHygyG1q2EkrMR", name: "Aesha S", classId: "21"},
{id: "recG86zV6CHYF7VfF", name: "Keaton F", classId: "22"},
{id: "recABfRdHBID55ITb", name: "Jack L", classId: "23"},
{id: "recoPTqEMH3j8hnoj", name: "Gabriella A", classId: "21"},
{id: "recnupF1UAqD28G3e", name: "Luiza S", classId: "21"},
{id: "recLBGpIXKINdKQPD", name: "Eddi B", classId: "21"},
{id: "reczg2YLztCTSVAkJ", name: "Zayn K", classId: "21"},
{id: "recAslBmjMjCZvsbw", name: "Tyrese U", classId: "23"},
{id: "recse0hurh7I6VYDU", name: "Rayan A", classId: "22"},
{id: "receVFl6MzKkfJ5Oo", name: "Aaleya K", classId: "21"},
{id: "rec10kkqTgAM61bqN", name: "Juvitta K", classId: "22"},
{id: "rect7AYlZThVpU2ql", name: "Melanie P", classId: "21"},
{id: "rec8WxNObzQBqnEBQ", name: "Panchikaa R", classId: "23"},
{id: "recqGTWZ4f66hfPtQ", name: "Hope M", classId: "23"},
{id: "recLbHg9Z8oUryqIC", name: "Ellie S", classId: "22"},
{id: "recq42FSORYKElFPd", name: "Gabriel C", classId: "22"},
{id: "reczuX1yGU4hwypDB", name: "Emili E", classId: "23"},
{id: "recAxyJJR2jl3kxJ6", name: "Blake W", classId: "22"},
{id: "rec7Kq0vCJmM3YCrD", name: "Ciyona M", classId: "22"},
{id: "reclxrzaleTnLdLPw", name: "Owen C", classId: "22"},
{id: "recVlZfiXr7LT2mVh", name: "Kinsey M", classId: "22"},
{id: "recEdgd7mGmQg8J5T", name: "Jiten B", classId: "22"},
{id: "recvPRXfTJdBG1jZz", name: "Memphis L", classId: "21"},
{id: "recXfDURSnwiShUCO", name: "Demi O", classId: "23"},
{id: "recJjVgoFccfsBvpH", name: "Archie P", classId: "23"},
{id: "recj5RUcUy9qioON6", name: "Winnie H", classId: "23"},
{id: "recgOgaT7IVnc9kVn", name: "George C", classId: "23"},
{id: "recqlAN1MchKWyX1V", name: "Louie T", classId: "23"},
{id: "recufrmK6h8ftBaXO", name: "Seirah A", classId: "23"},
{id: "recBEyDJspkY6qwZY", name: "Adriel C", classId: "21"},
{id: "rec0FbPnGyJkiG4BT", name: "James M", classId: "23"},
{id: "rec1v1ZwrjhSmhFiK", name: "Milana K", classId: "22"},
{id: "recXnAMWgXvXLUFjV", name: "Filip S", classId: "22"},
{id: "recpHLsXgC5LfA2np", name: "Thomas K", classId: "23"},
{id: "recVjf3VeK9JpZu9n", name: "Ishan J", classId: "22"},
{id: "recWLAtRJowUKkZTs", name: "Andrea A", classId: "21"},
{id: "recGZzky3Z5q5sOT4", name: "Chiedza C", classId: "21"},
{id: "reciPt76Gf7ULlimj", name: "Ennie N", classId: "23"},
{id: "recKDfVrDsxfvlQnk", name: "Clara B", classId: "22"},
{id: "rectm0Zn72NGyJAYP", name: "Zuzanna J", classId: "21"},
{id: "recoX5AZygenj5Hvs", name: "Poppy S", classId: "22"},
{id: "rec41FTsLuu43T0MA", name: "Tinatseyi C", classId: "23"},
{id: "recHQrF7f3YkEdCub", name: "Marian M", classId: "22"},
{id: "recNVlLySA6zKvZ8D", name: "Lloyd B", classId: "23"},
{id: "recmg4gHoGrLjPc0R", name: "Freya A", classId: "23"},
{id: "recsvkqYBaU8SFpyN", name: "Rokia S", classId: "22"},
{id: "recq4AhQoHRacTFxC", name: "Lily M", classId: "21"},
{id: "recbxA8biMB8CmoJN", name: "Aarav B", classId: "22"},
{id: "recXk01x6H4hJIaR3", name: "Lacie R", classId: "23"},
{id: "rec98eMRb3Rb8cyar", name: "Alexandru S", classId: "23"},
{id: "recRGF2nPLkfy7rVz", name: "Yarik S", classId: "23"},
{id: "recIqwZ3EhNv4E7y9", name: "Sarah V", classId: "22"},
{id: "rece5YYh7hMEw8YFG", name: "Denise K", classId: "21"},
{id: "recBLm0U7SD52cM1V", name: "Fadzai J", classId: "21"},
{id: "reca3yAawm0KIHLXv", name: "Henry M", classId: "21"},
{id: "recqnF7zMYcST7RQx", name: "Christopher T", classId: "23"},
{id: "recxuKDB7VMQ7p67o", name: "Einoras R", classId: "21"},
{id: "recuo81HuKck8FpZA", name: "Kedric R", classId: "21"},
{id: "recemyMnkOdMfxrCP", name: "Juniper B", classId: "21"},
{id: "recf1QHbsrkBlGNDY", name: "Wiktoria W", classId: "23"},
{id: "rec1dpAbLlqlXpeAv", name: "Jasper M", classId: "23"},
{id: "recK3bLcZOCpKKhVD", name: "Daisy J", classId: "22"},
{id: "recSrTla9Nejxvw9e", name: "Eli L", classId: "21"},
{id: "recMISNTjvYp9YBnl", name: "Meyrem H", classId: "22"},
{id: "recHttiXL6aWyyqmM", name: "Dakota T", classId: "21"},
];

const studentWritingPieces: StudentWritingPiece[] = [
    {id: "6201", studentId: "recTq8N4mk7EpuOcM", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6201_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6201_Page_02.jpg"}]},
{id: "6202", studentId: "recbE1Xg5cBDf7bnP", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6202_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6202_Page_02.jpg"}]},
{id: "6203", studentId: "recIfDi176HvbTpCQ", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6203_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6203_Page_02.jpg"}]},
{id: "6204", studentId: "recYnFNaO0S0jNKDY", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6204_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6204_Page_02.jpg"}]},
{id: "6206", studentId: "recNRiZpL0EM398jO", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6206_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6206_Page_02.jpg"}]},
{id: "6208", studentId: "recftCWT5pqtAtM5S", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6208_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6208_Page_02.jpg"}]},
{id: "6209", studentId: "recuHygyG1q2EkrMR", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6209_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6209_Page_02.jpg"}]},
{id: "6210", studentId: "recG86zV6CHYF7VfF", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6210_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6210_Page_02.jpg"}]},
{id: "6211", studentId: "recABfRdHBID55ITb", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6211_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6211_Page_02.jpg"}]},
{id: "6212", studentId: "recoPTqEMH3j8hnoj", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6212_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6212_Page_02.jpg"}]},
{id: "6213", studentId: "recnupF1UAqD28G3e", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6213_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6213_Page_02.jpg"}]},
{id: "6214", studentId: "recLBGpIXKINdKQPD", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6214_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6214_Page_02.jpg"}]},
{id: "6215", studentId: "reczg2YLztCTSVAkJ", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6215_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6215_Page_02.jpg"}]},
{id: "6216", studentId: "recAslBmjMjCZvsbw", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6216_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6216_Page_02.jpg"}]},
{id: "6217", studentId: "recse0hurh7I6VYDU", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6217_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6217_Page_02.jpg"}]},
{id: "6220", studentId: "receVFl6MzKkfJ5Oo", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6220_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6220_Page_02.jpg"}]},
{id: "6222", studentId: "rec10kkqTgAM61bqN", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6222_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6222_Page_02.jpg"}]},
{id: "6224", studentId: "rect7AYlZThVpU2ql", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6224_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6224_Page_02.jpg"}]},
{id: "6226", studentId: "rec8WxNObzQBqnEBQ", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6226_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6226_Page_02.jpg"}]},
{id: "6228", studentId: "recqGTWZ4f66hfPtQ", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6228_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6228_Page_02.jpg"}]},
{id: "6229", studentId: "recLbHg9Z8oUryqIC", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6229_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6229_Page_02.jpg"}]},
{id: "6231", studentId: "recq42FSORYKElFPd", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6231_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6231_Page_02.jpg"}]},
{id: "6232", studentId: "reczuX1yGU4hwypDB", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6232_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6232_Page_02.jpg"}]},
{id: "6236", studentId: "recAxyJJR2jl3kxJ6", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6236_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6236_Page_02.jpg"}]},
{id: "6238", studentId: "rec7Kq0vCJmM3YCrD", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6238_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6238_Page_02.jpg"}]},
{id: "6239", studentId: "reclxrzaleTnLdLPw", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6239_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6239_Page_02.jpg"}, {page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6239_Page_03.jpg"}, {page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6239_Page_04.jpg"}]},
{id: "6240", studentId: "recVlZfiXr7LT2mVh", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6240_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6240_Page_02.jpg"}]},
{id: "6243", studentId: "recEdgd7mGmQg8J5T", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6243_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6243_Page_02.jpg"}]},
{id: "6244", studentId: "recvPRXfTJdBG1jZz", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6244_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6244_Page_02.jpg"}]},
{id: "6245", studentId: "recXfDURSnwiShUCO", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6245_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6245_Page_02.jpg"}]},
{id: "6246", studentId: "recJjVgoFccfsBvpH", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6246_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6246_Page_02.jpg"}]},
{id: "6248", studentId: "recj5RUcUy9qioON6", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6248_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6248_Page_02.jpg"}]},
{id: "6251", studentId: "recgOgaT7IVnc9kVn", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6251_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6251_Page_02.jpg"}]},
{id: "6253", studentId: "recqlAN1MchKWyX1V", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6253_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6253_Page_02.jpg"}]},
{id: "6254", studentId: "recufrmK6h8ftBaXO", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6254_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6254_Page_02.jpg"}]},
{id: "6256", studentId: "recBEyDJspkY6qwZY", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6256_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6256_Page_02.jpg"}]},
{id: "6257", studentId: "rec0FbPnGyJkiG4BT", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6257_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6257_Page_02.jpg"}]},
{id: "6258", studentId: "rec1v1ZwrjhSmhFiK", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6258_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6258_Page_02.jpg"}]},
{id: "6259", studentId: "recXnAMWgXvXLUFjV", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6259_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6259_Page_02.jpg"}, {page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6259_Page_03.jpg"}, {page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6259_Page_04.jpg"}]},
{id: "6260", studentId: "recpHLsXgC5LfA2np", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6260_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6260_Page_02.jpg"}]},
{id: "6265", studentId: "recVjf3VeK9JpZu9n", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6265_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6265_Page_02.jpg"}]},
{id: "6267", studentId: "recWLAtRJowUKkZTs", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6267_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6267_Page_02.jpg"}]},
{id: "6268", studentId: "recGZzky3Z5q5sOT4", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6268_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6268_Page_02.jpg"}]},
{id: "6269", studentId: "reciPt76Gf7ULlimj", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6269_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6269_Page_02.jpg"}]},
{id: "6271", studentId: "recKDfVrDsxfvlQnk", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6271_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6271_Page_02.jpg"}, {page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6271_Page_03.jpg"}, {page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6271_Page_04.jpg"}]},
{id: "6272", studentId: "rectm0Zn72NGyJAYP", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6272_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6272_Page_02.jpg"}]},
{id: "6273", studentId: "recoX5AZygenj5Hvs", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6273_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6273_Page_02.jpg"}]},
{id: "6276", studentId: "rec41FTsLuu43T0MA", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6276_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6276_Page_02.jpg"}]},
{id: "6277", studentId: "recHQrF7f3YkEdCub", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6277_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6277_Page_02.jpg"}]},
{id: "6279", studentId: "recNVlLySA6zKvZ8D", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6279_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6279_Page_02.jpg"}]},
{id: "6280", studentId: "recmg4gHoGrLjPc0R", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6280_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6280_Page_02.jpg"}]},
{id: "6281", studentId: "recsvkqYBaU8SFpyN", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6281_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6281_Page_02.jpg"}]},
{id: "6283", studentId: "recq4AhQoHRacTFxC", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6283_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6283_Page_02.jpg"}]},
{id: "6284", studentId: "recbxA8biMB8CmoJN", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6284_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6284_Page_02.jpg"}]},
{id: "6286", studentId: "recXk01x6H4hJIaR3", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6286_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6286_Page_02.jpg"}]},
{id: "6287", studentId: "rec98eMRb3Rb8cyar", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6287_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6287_Page_02.jpg"}]},
{id: "6289", studentId: "recRGF2nPLkfy7rVz", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6289_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6289_Page_02.jpg"}]},
{id: "6291", studentId: "recIqwZ3EhNv4E7y9", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6291_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6291_Page_02.jpg"}]},
{id: "6293", studentId: "rece5YYh7hMEw8YFG", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6293_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6293_Page_02.jpg"}]},
{id: "6295", studentId: "recBLm0U7SD52cM1V", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6295_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6295_Page_02.jpg"}]},
{id: "6297", studentId: "reca3yAawm0KIHLXv", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6297_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6297_Page_02.jpg"}]},
{id: "6299", studentId: "recqnF7zMYcST7RQx", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6299_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6299_Page_02.jpg"}]},
{id: "6300", studentId: "recxuKDB7VMQ7p67o", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6300_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6300_Page_02.jpg"}]},
{id: "6301", studentId: "recuo81HuKck8FpZA", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6301_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6301_Page_02.jpg"}]},
{id: "6304", studentId: "recemyMnkOdMfxrCP", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6304_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6304_Page_02.jpg"}]},
{id: "6305", studentId: "recf1QHbsrkBlGNDY", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6305_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6305_Page_02.jpg"}]},
{id: "6306", studentId: "rec1dpAbLlqlXpeAv", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6306_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6306_Page_02.jpg"}]},
{id: "6307", studentId: "recK3bLcZOCpKKhVD", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6307_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6307_Page_02.jpg"}]},
{id: "6308", studentId: "recSrTla9Nejxvw9e", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6308_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6308_Page_02.jpg"}]},
{id: "6310", studentId: "recMISNTjvYp9YBnl", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6310_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6310_Page_02.jpg"}]},
{id: "6312", studentId: "recHttiXL6aWyyqmM", writingPieceId: "6", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6312_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6312_Page_02.jpg"}]},
];

const scores: StylusCriteriaScore[] = [
    {id: "1", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "2", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "3", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "4", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "5", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "6", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "7", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "8", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "9", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "10", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "11", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "12", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "13", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "14", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "15", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "16", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "17", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "18", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "19", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "20", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "21", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "22", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "23", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "24", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "25", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "26", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "27", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "28", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "29", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "30", studentId: "recTq8N4mk7EpuOcM", studentWritingPieceId: "6201", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "31", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "32", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "33", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "34", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "35", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "36", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "37", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "38", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "39", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "40", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "41", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "42", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "43", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "44", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "45", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "46", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "47", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "48", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "49", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "50", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "51", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "52", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "53", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "54", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "55", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "56", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "57", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "58", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "59", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "60", studentId: "recbE1Xg5cBDf7bnP", studentWritingPieceId: "6202", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "61", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "62", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "63", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "64", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "65", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "66", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "67", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "68", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "69", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "70", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "71", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "72", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "73", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "74", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "75", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "76", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "77", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "78", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "79", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "80", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "81", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "82", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "83", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "84", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "85", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "86", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "87", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "88", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "89", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "90", studentId: "recIfDi176HvbTpCQ", studentWritingPieceId: "6203", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "91", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "92", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "93", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "94", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "95", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "96", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "97", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "98", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "99", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "100", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "101", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "102", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "103", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "104", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "105", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "106", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "107", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "108", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "109", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "110", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "111", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "112", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "113", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "114", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "115", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "116", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "117", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "118", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "119", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "120", studentId: "recYnFNaO0S0jNKDY", studentWritingPieceId: "6204", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "121", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "122", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "123", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "124", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "125", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "126", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "127", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "128", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "129", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "130", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "131", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "132", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "133", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "134", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "135", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "136", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "137", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "138", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "139", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "140", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "141", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "142", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "143", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "144", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "145", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "146", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "147", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "148", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "149", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "150", studentId: "recNRiZpL0EM398jO", studentWritingPieceId: "6206", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "151", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "152", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "153", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "154", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "155", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "156", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "157", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "158", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "159", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "160", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "161", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "162", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "163", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "164", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "165", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "166", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "167", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "168", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "169", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "170", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "31", score: 0, examples: {negative: [], positive: []}},
{id: "171", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "172", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "173", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "174", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "175", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "176", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "177", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "178", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "179", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "180", studentId: "recftCWT5pqtAtM5S", studentWritingPieceId: "6208", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "181", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "182", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "183", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "184", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "185", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "186", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "187", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "188", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "189", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "190", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "191", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "192", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "193", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "194", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "195", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "196", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "197", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "198", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "199", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "200", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "201", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "202", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "203", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "204", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "205", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "206", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "207", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "208", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "209", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "210", studentId: "recuHygyG1q2EkrMR", studentWritingPieceId: "6209", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "211", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "212", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "213", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "214", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "215", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "216", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "217", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "218", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "219", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "220", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "221", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "222", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "223", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "224", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "225", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "226", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "227", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "228", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "229", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "230", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "231", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "232", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "233", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "234", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "235", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "236", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "237", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "238", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "239", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "240", studentId: "recG86zV6CHYF7VfF", studentWritingPieceId: "6210", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "241", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "242", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "243", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "244", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "245", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "246", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "247", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "248", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "249", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "250", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "251", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "252", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "253", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "254", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "255", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "256", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "257", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "258", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "259", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "260", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "261", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "262", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "263", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "264", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "265", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "266", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "267", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "268", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "269", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "270", studentId: "recABfRdHBID55ITb", studentWritingPieceId: "6211", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "271", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "272", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "273", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "274", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "275", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "276", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "277", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "278", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "279", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "280", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "281", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "282", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "283", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "284", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "285", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "286", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "287", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "288", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "289", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "290", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "291", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "292", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "293", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "294", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "295", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "296", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "297", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "298", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "299", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "300", studentId: "recoPTqEMH3j8hnoj", studentWritingPieceId: "6212", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "301", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "302", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "303", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "304", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "305", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "306", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "307", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "308", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "309", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "310", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "311", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "312", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "313", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "314", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "315", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "316", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "317", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "318", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "319", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "320", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "321", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "322", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "323", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "324", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "325", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "326", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "327", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "328", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "329", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "330", studentId: "recnupF1UAqD28G3e", studentWritingPieceId: "6213", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "331", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "332", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "333", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "334", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "335", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "336", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "337", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "338", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "339", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "340", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "341", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "342", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "343", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "344", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "345", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "346", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "347", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "348", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "349", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "350", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "351", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "352", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "353", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "354", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "355", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "356", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "357", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "358", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "359", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "360", studentId: "recLBGpIXKINdKQPD", studentWritingPieceId: "6214", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "361", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "362", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "363", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "364", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "365", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "366", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "367", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "368", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "369", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "370", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "371", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "372", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "373", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "374", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "375", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "376", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "377", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "378", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "379", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "380", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "381", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "382", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "383", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "384", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "385", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "386", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "387", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "388", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "389", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "390", studentId: "reczg2YLztCTSVAkJ", studentWritingPieceId: "6215", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "391", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "392", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "393", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "394", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "395", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "396", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "397", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "398", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "399", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "400", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "401", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "402", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "403", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "404", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "405", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "406", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "407", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "408", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "409", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "410", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "411", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "412", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "413", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "414", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "415", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "416", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "417", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "418", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "419", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "420", studentId: "recAslBmjMjCZvsbw", studentWritingPieceId: "6216", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "421", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "422", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "423", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "424", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "425", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "426", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "427", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "428", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "429", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "430", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "431", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "432", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "433", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "434", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "435", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "436", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "437", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "438", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "439", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "440", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "441", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "442", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "443", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "444", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "445", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "446", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "447", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "448", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "449", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "450", studentId: "recse0hurh7I6VYDU", studentWritingPieceId: "6217", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "451", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "452", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "453", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "454", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "455", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "456", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "457", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "458", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "459", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "460", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "461", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "462", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "463", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "464", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "465", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "466", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "467", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "468", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "469", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "470", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "471", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "472", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "473", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "474", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "475", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "476", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "477", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "478", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "479", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "480", studentId: "receVFl6MzKkfJ5Oo", studentWritingPieceId: "6220", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "481", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "482", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "483", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "484", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "485", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "486", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "487", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "488", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "489", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "490", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "491", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "492", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "493", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "494", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "495", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "496", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "497", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "498", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "499", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "500", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "501", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "502", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "503", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "504", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "505", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "506", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "507", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "508", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "509", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "510", studentId: "rec10kkqTgAM61bqN", studentWritingPieceId: "6222", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "511", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "512", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "513", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "514", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "515", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "516", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "517", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "518", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "519", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "520", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "521", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "522", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "523", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "524", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "525", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "526", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "527", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "528", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "529", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "530", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "531", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "532", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "533", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "534", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "535", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "536", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "537", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "538", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "539", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "540", studentId: "rect7AYlZThVpU2ql", studentWritingPieceId: "6224", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "541", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "542", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "543", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "544", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "545", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "546", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "547", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "548", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "549", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "550", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "551", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "552", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "553", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "554", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "555", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "556", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "557", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "558", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "559", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "560", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "561", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "562", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "563", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "564", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "565", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "566", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "567", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "568", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "569", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "570", studentId: "rec8WxNObzQBqnEBQ", studentWritingPieceId: "6226", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "571", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "572", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "573", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "574", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "575", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "576", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "577", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "578", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "579", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "580", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "581", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "582", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "583", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "584", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "585", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "586", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "587", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "588", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "589", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "590", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "591", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "592", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "593", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "594", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "595", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "596", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "597", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "598", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "599", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "600", studentId: "recqGTWZ4f66hfPtQ", studentWritingPieceId: "6228", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "601", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "602", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "603", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "604", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "605", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "606", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "607", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "608", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "609", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "610", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "611", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "612", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "613", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "614", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "615", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "616", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "617", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "618", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "619", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "620", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "621", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "622", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "623", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "624", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "625", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "626", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "627", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "628", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "629", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "630", studentId: "recLbHg9Z8oUryqIC", studentWritingPieceId: "6229", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "631", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "632", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "633", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "634", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "635", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "636", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "637", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "638", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "639", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "640", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "641", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "642", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "643", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "644", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "645", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "646", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "647", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "648", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "649", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "650", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "651", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "652", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "653", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "654", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "655", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "656", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "657", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "658", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "659", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "660", studentId: "recq42FSORYKElFPd", studentWritingPieceId: "6231", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "661", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "662", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "663", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "664", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "665", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "666", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "667", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "668", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "669", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "670", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "671", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "672", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "673", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "674", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "675", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "676", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "677", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "678", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "679", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "680", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "681", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "682", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "683", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "684", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "685", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "686", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "687", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "688", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "689", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "690", studentId: "reczuX1yGU4hwypDB", studentWritingPieceId: "6232", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "691", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "692", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "693", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "694", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "695", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "696", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "697", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "698", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "699", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "700", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "701", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "702", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "703", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "704", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "705", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "706", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "707", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "708", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "709", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "710", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "711", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "712", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "713", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "714", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "715", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "716", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "717", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "718", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "719", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "720", studentId: "recAxyJJR2jl3kxJ6", studentWritingPieceId: "6236", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "721", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "722", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "723", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "724", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "725", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "726", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "727", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "728", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "729", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "730", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "731", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "732", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "733", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "734", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "735", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "736", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "737", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "738", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "739", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "740", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "741", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "742", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "743", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "744", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "745", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "746", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "747", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "748", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "749", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "750", studentId: "rec7Kq0vCJmM3YCrD", studentWritingPieceId: "6238", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "751", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "752", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "753", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "754", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "755", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "756", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "757", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "758", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "759", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "760", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "761", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "762", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "763", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "764", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "765", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "766", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "767", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "768", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "769", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "770", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "771", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "772", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "773", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "774", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "775", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "776", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "777", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "778", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "779", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "780", studentId: "reclxrzaleTnLdLPw", studentWritingPieceId: "6239", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "781", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "782", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "783", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "784", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "785", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "786", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "787", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "788", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "789", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "790", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "791", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "792", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "793", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "794", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "795", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "796", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "797", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "798", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "799", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "800", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "801", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "802", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "803", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "804", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "805", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "806", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "807", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "808", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "809", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "810", studentId: "recVlZfiXr7LT2mVh", studentWritingPieceId: "6240", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "811", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "812", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "813", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "814", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "815", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "816", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "817", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "818", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "819", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "820", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "821", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "822", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "823", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "824", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "825", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "826", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "827", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "828", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "829", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "830", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "831", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "832", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "833", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "834", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "835", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "836", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "837", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "838", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "839", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "840", studentId: "recEdgd7mGmQg8J5T", studentWritingPieceId: "6243", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "841", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "842", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "843", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "844", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "845", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "846", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "847", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "848", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "849", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "850", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "851", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "852", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "853", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "854", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "855", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "856", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "857", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "858", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "859", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "860", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "861", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "862", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "863", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "864", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "865", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "866", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "867", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "868", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "869", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "870", studentId: "recvPRXfTJdBG1jZz", studentWritingPieceId: "6244", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "871", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "872", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "873", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "874", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "875", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "876", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "877", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "878", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "879", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "880", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "881", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "882", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "883", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "884", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "885", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "886", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "887", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "888", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "889", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "890", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "891", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "892", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "893", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "894", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "895", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "896", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "897", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "898", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "899", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "900", studentId: "recXfDURSnwiShUCO", studentWritingPieceId: "6245", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "901", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "902", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "903", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "904", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "905", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "906", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "907", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "908", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "909", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "910", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "911", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "912", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "913", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "914", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "915", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "916", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "917", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "918", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "919", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "920", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "921", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "922", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "923", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "924", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "925", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "926", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "927", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "928", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "929", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "930", studentId: "recJjVgoFccfsBvpH", studentWritingPieceId: "6246", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "931", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "932", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "933", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "934", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "935", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "936", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "937", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "938", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "939", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "940", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "941", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "942", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "943", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "944", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "945", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "946", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "947", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "948", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "949", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "950", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "951", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "952", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "953", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "954", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "955", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "956", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "957", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "958", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "959", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "960", studentId: "recj5RUcUy9qioON6", studentWritingPieceId: "6248", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "961", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "962", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "963", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "964", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "965", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "966", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "967", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "968", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "969", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "970", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "971", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "972", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "973", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "974", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "975", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "976", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "977", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "978", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "979", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "980", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "981", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "982", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "983", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "984", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "985", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "986", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "987", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "988", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "989", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "990", studentId: "recgOgaT7IVnc9kVn", studentWritingPieceId: "6251", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "991", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "992", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "993", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "994", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "995", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "996", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "997", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "998", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "999", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1000", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1001", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "1002", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1003", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "1004", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "1005", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1006", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1007", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1008", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "29", score: 0, examples: {negative: [], positive: []}},
{id: "1009", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1010", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1011", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1012", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1013", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1014", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1015", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1016", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1017", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1018", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1019", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1020", studentId: "recqlAN1MchKWyX1V", studentWritingPieceId: "6253", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1021", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1022", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1023", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1024", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1025", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1026", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1027", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1028", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1029", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1030", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1031", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1032", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1033", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1034", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1035", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1036", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1037", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1038", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1039", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1040", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1041", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "1042", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1043", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "1044", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1045", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1046", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1047", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1048", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "1049", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1050", studentId: "recufrmK6h8ftBaXO", studentWritingPieceId: "6254", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1051", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1052", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "1053", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1054", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1055", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1056", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1057", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1058", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1059", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1060", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1061", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1062", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1063", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1064", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1065", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1066", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1067", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1068", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1069", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1070", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1071", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "1072", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1073", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1074", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1075", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1076", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1077", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "1078", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1079", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1080", studentId: "recBEyDJspkY6qwZY", studentWritingPieceId: "6256", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1081", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1082", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1083", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1084", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1085", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1086", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1087", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "1088", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1089", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1090", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1091", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1092", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1093", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1094", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1095", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1096", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1097", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1098", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1099", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1100", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1101", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1102", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1103", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1104", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "1105", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1106", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1107", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1108", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1109", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1110", studentId: "rec0FbPnGyJkiG4BT", studentWritingPieceId: "6257", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1111", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1112", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1113", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1114", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1115", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1116", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1117", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1118", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1119", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1120", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1121", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1122", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1123", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1124", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1125", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1126", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1127", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1128", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1129", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1130", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1131", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1132", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1133", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1134", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1135", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1136", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1137", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1138", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1139", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1140", studentId: "rec1v1ZwrjhSmhFiK", studentWritingPieceId: "6258", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1141", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1142", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1143", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1144", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1145", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1146", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1147", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1148", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1149", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1150", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1151", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1152", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1153", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1154", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1155", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1156", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1157", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1158", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1159", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1160", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1161", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1162", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1163", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1164", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "1165", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1166", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1167", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "1168", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1169", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1170", studentId: "recXnAMWgXvXLUFjV", studentWritingPieceId: "6259", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1171", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1172", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1173", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "1174", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1175", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1176", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1177", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "1178", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1179", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1180", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1181", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1182", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1183", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1184", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1185", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1186", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1187", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1188", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1189", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1190", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1191", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1192", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1193", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1194", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1195", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1196", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1197", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1198", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "1199", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1200", studentId: "recpHLsXgC5LfA2np", studentWritingPieceId: "6260", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1201", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1202", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1203", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1204", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1205", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1206", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1207", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1208", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1209", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1210", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1211", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1212", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1213", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1214", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1215", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1216", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1217", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1218", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1219", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1220", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1221", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1222", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1223", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1224", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1225", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1226", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1227", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1228", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1229", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1230", studentId: "recVjf3VeK9JpZu9n", studentWritingPieceId: "6265", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1231", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1232", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1233", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1234", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1235", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1236", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1237", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1238", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "1239", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1240", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1241", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1242", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1243", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1244", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1245", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1246", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1247", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1248", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1249", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1250", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1251", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1252", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1253", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1254", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1255", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1256", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "1257", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1258", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "1259", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1260", studentId: "recWLAtRJowUKkZTs", studentWritingPieceId: "6267", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1261", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1262", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1263", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1264", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1265", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1266", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1267", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1268", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "1269", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1270", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1271", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1272", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1273", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1274", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1275", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1276", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1277", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1278", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1279", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1280", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1281", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "1282", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1283", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1284", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "1285", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1286", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1287", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1288", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "1289", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1290", studentId: "recGZzky3Z5q5sOT4", studentWritingPieceId: "6268", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1291", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1292", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "1293", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1294", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "1295", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1296", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1297", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1298", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "1299", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1300", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1301", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1302", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1303", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "1304", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1305", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1306", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1307", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1308", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "29", score: 0, examples: {negative: [], positive: []}},
{id: "1309", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1310", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1311", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1312", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1313", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1314", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1315", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1316", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1317", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1318", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "1319", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1320", studentId: "reciPt76Gf7ULlimj", studentWritingPieceId: "6269", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1321", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1322", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1323", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1324", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1325", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1326", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1327", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1328", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "1329", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1330", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1331", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1332", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1333", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1334", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1335", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1336", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1337", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1338", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1339", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1340", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1341", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1342", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1343", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1344", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1345", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1346", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "1347", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1348", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1349", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1350", studentId: "recKDfVrDsxfvlQnk", studentWritingPieceId: "6271", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1351", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1352", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1353", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "1354", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1355", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1356", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1357", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1358", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1359", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1360", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1361", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "1362", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1363", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1364", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1365", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1366", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1367", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1368", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1369", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1370", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1371", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1372", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1373", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1374", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1375", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1376", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1377", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1378", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1379", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1380", studentId: "rectm0Zn72NGyJAYP", studentWritingPieceId: "6272", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1381", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1382", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1383", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1384", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1385", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "1386", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "1387", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "1388", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1389", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1390", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1391", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1392", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1393", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1394", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1395", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1396", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1397", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "1398", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1399", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1400", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1401", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1402", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1403", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1404", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1405", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1406", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1407", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1408", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1409", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1410", studentId: "recoX5AZygenj5Hvs", studentWritingPieceId: "6273", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1411", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1412", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1413", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1414", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1415", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1416", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1417", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1418", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1419", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1420", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1421", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1422", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1423", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1424", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1425", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1426", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1427", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1428", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1429", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1430", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "1431", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1432", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1433", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1434", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1435", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1436", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1437", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1438", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1439", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1440", studentId: "rec41FTsLuu43T0MA", studentWritingPieceId: "6276", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1441", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1442", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "1443", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1444", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "1445", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1446", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1447", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1448", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1449", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1450", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1451", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "1452", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1453", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "1454", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1455", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "1456", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1457", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1458", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1459", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1460", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "1461", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1462", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1463", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1464", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1465", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1466", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1467", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1468", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1469", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "1470", studentId: "recHQrF7f3YkEdCub", studentWritingPieceId: "6277", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1471", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1472", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1473", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1474", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "1475", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1476", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1477", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "1478", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1479", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1480", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1481", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1482", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1483", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1484", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1485", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1486", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1487", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1488", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1489", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1490", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1491", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1492", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1493", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1494", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1495", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1496", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1497", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1498", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "1499", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "1500", studentId: "recNVlLySA6zKvZ8D", studentWritingPieceId: "6279", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1501", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1502", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1503", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1504", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1505", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1506", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1507", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1508", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1509", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1510", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1511", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1512", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1513", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1514", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "1515", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "1516", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1517", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "1518", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1519", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1520", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1521", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1522", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1523", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1524", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1525", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1526", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1527", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1528", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1529", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1530", studentId: "recmg4gHoGrLjPc0R", studentWritingPieceId: "6280", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1531", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1532", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1533", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1534", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1535", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1536", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1537", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1538", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1539", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1540", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1541", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "1542", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1543", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1544", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1545", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "1546", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1547", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1548", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1549", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1550", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1551", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1552", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1553", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1554", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1555", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1556", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1557", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1558", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1559", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "1560", studentId: "recsvkqYBaU8SFpyN", studentWritingPieceId: "6281", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1561", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1562", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1563", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1564", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1565", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1566", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1567", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "1568", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1569", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1570", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1571", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "1572", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1573", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1574", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1575", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1576", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1577", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1578", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1579", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1580", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1581", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1582", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1583", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "1584", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1585", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1586", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1587", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1588", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1589", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1590", studentId: "recq4AhQoHRacTFxC", studentWritingPieceId: "6283", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1591", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1592", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1593", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1594", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "1595", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1596", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1597", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1598", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1599", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1600", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1601", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1602", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1603", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "1604", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1605", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "1606", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1607", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1608", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1609", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1610", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1611", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1612", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1613", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1614", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1615", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1616", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1617", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1618", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1619", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1620", studentId: "recbxA8biMB8CmoJN", studentWritingPieceId: "6284", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1621", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "1622", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1623", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1624", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1625", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1626", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1627", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1628", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1629", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1630", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1631", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1632", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1633", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1634", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1635", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "1636", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1637", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1638", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1639", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1640", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1641", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1642", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1643", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1644", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1645", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1646", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1647", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1648", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1649", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1650", studentId: "recXk01x6H4hJIaR3", studentWritingPieceId: "6286", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1651", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1652", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1653", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1654", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1655", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1656", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1657", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1658", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1659", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1660", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1661", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1662", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1663", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1664", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1665", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1666", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1667", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1668", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1669", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1670", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1671", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "1672", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1673", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1674", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1675", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1676", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1677", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1678", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "1679", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "1680", studentId: "rec98eMRb3Rb8cyar", studentWritingPieceId: "6287", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1681", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1682", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1683", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1684", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1685", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1686", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1687", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1688", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1689", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1690", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1691", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1692", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1693", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1694", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1695", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1696", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1697", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "1698", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1699", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1700", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1701", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1702", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1703", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1704", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1705", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1706", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1707", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1708", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1709", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1710", studentId: "recRGF2nPLkfy7rVz", studentWritingPieceId: "6289", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1711", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1712", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1713", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1714", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1715", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1716", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1717", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1718", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1719", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1720", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1721", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1722", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1723", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1724", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1725", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1726", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1727", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1728", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1729", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1730", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1731", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1732", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1733", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1734", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1735", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "1736", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1737", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1738", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1739", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "1740", studentId: "recIqwZ3EhNv4E7y9", studentWritingPieceId: "6291", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1741", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1742", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1743", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1744", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1745", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1746", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1747", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "1748", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1749", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1750", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1751", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1752", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1753", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1754", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1755", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1756", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1757", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1758", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1759", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1760", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1761", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1762", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1763", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1764", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1765", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1766", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1767", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1768", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "1769", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1770", studentId: "rece5YYh7hMEw8YFG", studentWritingPieceId: "6293", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1771", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1772", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1773", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1774", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1775", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1776", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1777", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "1778", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1779", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1780", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1781", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1782", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1783", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "1784", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1785", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1786", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1787", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1788", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1789", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1790", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1791", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1792", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1793", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1794", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1795", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1796", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1797", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "1798", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1799", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1800", studentId: "recBLm0U7SD52cM1V", studentWritingPieceId: "6295", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1801", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1802", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1803", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1804", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1805", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1806", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1807", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1808", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1809", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1810", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1811", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1812", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1813", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1814", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1815", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1816", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1817", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1818", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1819", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1820", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "1821", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "1822", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1823", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1824", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1825", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1826", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1827", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1828", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "1829", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1830", studentId: "reca3yAawm0KIHLXv", studentWritingPieceId: "6297", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1831", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1832", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "1833", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1834", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "1835", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1836", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1837", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1838", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1839", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1840", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1841", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1842", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1843", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "1844", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1845", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "1846", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1847", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "1848", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1849", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1850", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1851", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1852", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "1853", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1854", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "1855", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1856", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1857", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1858", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1859", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1860", studentId: "recqnF7zMYcST7RQx", studentWritingPieceId: "6299", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1861", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1862", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1863", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1864", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1865", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1866", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1867", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1868", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1869", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "1870", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1871", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "1872", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1873", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1874", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1875", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1876", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1877", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1878", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1879", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1880", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1881", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1882", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1883", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1884", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1885", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1886", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1887", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1888", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1889", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1890", studentId: "recxuKDB7VMQ7p67o", studentWritingPieceId: "6300", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1891", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1892", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1893", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "1894", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1895", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1896", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1897", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "18", score: 2, examples: {negative: [], positive: []}},
{id: "1898", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1899", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1900", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1901", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1902", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1903", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1904", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1905", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1906", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1907", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1908", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1909", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "1910", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1911", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1912", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1913", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1914", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "1915", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "1916", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "1917", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1918", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1919", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1920", studentId: "recuo81HuKck8FpZA", studentWritingPieceId: "6301", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "1921", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1922", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "1923", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1924", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1925", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1926", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1927", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1928", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1929", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1930", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "1931", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1932", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "1933", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1934", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "1935", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1936", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "1937", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1938", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1939", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "1940", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1941", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1942", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1943", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "1944", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "1945", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1946", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "1947", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "1948", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1949", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1950", studentId: "recemyMnkOdMfxrCP", studentWritingPieceId: "6304", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "1951", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "1952", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "1953", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1954", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1955", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1956", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1957", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1958", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1959", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1960", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "1961", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "1962", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "1963", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1964", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1965", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1966", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1967", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1968", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "1969", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "1970", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "1971", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "1972", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "1973", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "1974", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "1975", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "1976", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "1977", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "1978", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "1979", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "1980", studentId: "recf1QHbsrkBlGNDY", studentWritingPieceId: "6305", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "1981", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "1982", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "1983", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "1984", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "1985", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "1986", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "1987", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "1988", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "1989", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "1990", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "1991", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "1992", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "1993", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "1994", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "1995", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "1996", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "1997", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "1998", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "1999", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "2000", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "2001", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "2002", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "2003", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "2004", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "2005", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "2006", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "2007", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "2008", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "2009", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "2010", studentId: "rec1dpAbLlqlXpeAv", studentWritingPieceId: "6306", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "2011", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "2012", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "2013", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "2014", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "2015", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "2016", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "2017", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "2018", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "2019", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "20", score: 0, examples: {negative: [], positive: []}},
{id: "2020", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "2021", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "2022", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "2023", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "2024", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "2025", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "2026", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "2027", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "2028", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "2029", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "2030", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "2031", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "2032", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "2033", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "2034", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "2035", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "2036", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "2037", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "2038", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "2039", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "2040", studentId: "recK3bLcZOCpKKhVD", studentWritingPieceId: "6307", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "2041", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "2042", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "2043", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "2044", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "2045", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "2046", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "2047", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "2048", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "2049", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "2050", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "2051", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "2052", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "2053", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "2054", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "2055", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "2056", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "2057", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "2058", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "2059", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "2060", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "2061", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "2062", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "2063", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "2064", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "2065", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "2066", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "2067", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "2068", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "2069", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "40", score: 0, examples: {negative: [], positive: []}},
{id: "2070", studentId: "recSrTla9Nejxvw9e", studentWritingPieceId: "6308", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "2071", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "2072", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "2073", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "2074", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "2075", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "2076", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "2077", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "2078", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "2079", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "2080", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "2081", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "2082", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "2083", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "2084", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "2085", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "2086", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "2087", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "2088", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "2089", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "2090", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "2091", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "2092", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "2093", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "2094", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "2095", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "2096", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
{id: "2097", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "2098", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "2099", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "2100", studentId: "recMISNTjvYp9YBnl", studentWritingPieceId: "6310", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "2101", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "2", score: 1, examples: {negative: [], positive: []}},
{id: "2102", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "2103", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "2104", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "2105", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "2106", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "2107", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "2108", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "2109", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "2110", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "2111", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "2112", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "2113", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "2114", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "2115", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "2116", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "2117", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "2118", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "2119", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "2120", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "2121", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "2122", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "2123", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "2124", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "2125", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "2126", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "2127", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "2128", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "2129", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "2130", studentId: "recHttiXL6aWyyqmM", studentWritingPieceId: "6312", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},

].map(score => {
    const possibleScores = stylus_criteria.find(c => c.id === score.criteriaId)?.possibleScores;
    return {
      ...score,
      score: score.score / (possibleScores?.[possibleScores.length - 1] ?? 1)
    }
});

export default { classes, students, studentWritingPieces, scores }

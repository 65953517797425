import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { WritingPiece, KS2Data, StudentWritingPiece } from '../../types';
import ScanDisplay from '../ScanDisplay';
import ScoresDialog from './ScoresDialog';

interface Scan extends WritingPiece {
  swpId: string;
  studentName: string;
  pageCount: number;
  class: string;
}

interface ScanItemProps {
  scan: Scan;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onOpenDialog: (studentWritingPieceId: string) => void;
}

interface ScanListProps {
  scans: Scan[];
  selectedScans: string[];
  setSelectedScans: React.Dispatch<React.SetStateAction<string[]>>;
  data: KS2Data;
}

const ScanItem = ({ scan, isSelected, onSelect, onOpenDialog }: ScanItemProps) => {
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <Box sx={{ width: 460, display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1, 
            fontWeight: 'bold', 
            color: 'primary.main',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {scan.studentName}
        </Typography>
        <IconButton onClick={() => onOpenDialog(scan.swpId)} color="primary">
          <AssessmentIcon />
        </IconButton>
      </Box>
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 1,
          color: 'text.secondary',
          textAlign: 'left',
          width: '100%',
          margin: 0,
        }}
      >
        {scan.class}
      </Typography>
      <Typography 
        variant="h6" 
        sx={{ 
          mt: 1, 
          mb: 2, 
          color: 'text.secondary',
          textAlign: 'left',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginTop: 0,
        }}
      >
        {scan.name}
      </Typography>
      <ScanDisplay
        currentPage={currentPage}
        totalPages={scan.pageCount}
        onPreviousPage={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        onNextPage={() => setCurrentPage(prev => Math.min(prev + 1, scan.pageCount))}
        selectable
        selected={isSelected}
        onSelect={() => onSelect(scan.swpId)}
      />
    </Box>
  );
};

const ScanList = ({ scans, selectedScans, setSelectedScans, data }: ScanListProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<{ studentName: string; writingPieceName: string; scores: any[] }>({ studentName: '', writingPieceName: '', scores: [] });

  const handleOpenDialog = (studentWritingPieceId: string) => {
    const studentWritingPiece = data.studentWritingPieces.find(swp => swp.id === studentWritingPieceId);
    const student = data.students.find(s => s.id === studentWritingPiece?.studentId);
    const writingPiece = data.writingPieces.find(wp => wp.id === studentWritingPiece?.writingPieceId);
    
    const scores = data.scores
      .filter(score => score.studentWritingPieceId === studentWritingPieceId)
      .reduce((acc, score) => {
        const criteria = data.stylus_criteria.find(sc => sc.id === score.criteriaId);
        const ks2Criteria = data.ks2_criteria.find(kc => kc.id === criteria?.ks2CriteriaId);
        const level = data.levels.find(l => l.id === ks2Criteria?.levelId);
        const key = `${level?.name}_${ks2Criteria?.name}`;
        if (!acc[key]) {
          acc[key] = { sum: 0, count: 0 };
        }
        acc[key].sum += score.score || 0;
        acc[key].count++;
        return acc;
      }, {} as Record<string, { sum: number; count: number }>);

    const averagedScores = Object.entries(scores).map(([level_criteria, { sum, count }]) => ({
      levelName: level_criteria.split('_')[0],
      criteriaName: level_criteria.split('_')[1],
      score: sum / count
    }));

    setDialogData({
      studentName: student?.name || '',
      writingPieceName: writingPiece?.name || '',
      scores: averagedScores
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handleSelect = (id: string) => {
    setSelectedScans(prev => 
      prev.includes(id) ? prev.filter(scanId => scanId !== id) : [...prev, id]
    );
  };

  return (
    <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', p: 2, height: '100%' }}>
      <Box sx={{ display: 'inline-flex', gap: 3 }}>
        {scans.map((scan: Scan) => (
          <ScanItem 
            key={scan.swpId}
            scan={scan} 
            isSelected={selectedScans.includes(scan.swpId)}
            onSelect={handleSelect}
            onOpenDialog={handleOpenDialog}
          />
        ))}
      </Box>
      <ScoresDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        studentName={dialogData.studentName}
        writingPieceName={dialogData.writingPieceName}
        scores={dialogData.scores}
        ks2Data={data}
      />
    </Box>
  );
};

export default ScanList;

import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box } from "@mui/material"
import { mockData, kingsleyData, wollastonData, reephamData, hallMeadowData, ketteringParkJuniorData, littleHarrowdenData } from '../data'
import { KS2Data } from '../types'
import { KS2TeacherReportContent } from '../components/KS2TeacherReport'

const dataMap = {
  mock: mockData,
  kingsley: kingsleyData,
  wollaston: wollastonData,
  reepham: reephamData,
  'hall-meadow': hallMeadowData,
  'kettering-park-junior': ketteringParkJuniorData,
  'little-harrowden': littleHarrowdenData,
} as const;

const DATA_OPTIONS = {
  kingsley: 'Kingsley',
  wollaston: 'Wollaston',
  reepham: 'Reepham',
  'hall-meadow': 'Hall Meadow',
  'kettering-park-junior': 'Kettering Park Junior',
  'little-harrowden': 'Little Harrowden',
} as const;

export default function KS2TeacherReport() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState<KS2Data | null>(null)
  const [selectedDataKeys, setSelectedDataKeys] = useState<string[]>(
    searchParams.get('data')?.split(',') || []
  )

  const canChangeData = searchParams.get('inmat-admin') === 'true' || false
  
  // Update URL when selectedDataKeys changes
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams)
    if (selectedDataKeys.length) {
      newParams.set('data', selectedDataKeys.join(','))
    } else {
      newParams.delete('data')
    }
    setSearchParams(newParams, { replace: true })
  }, [selectedDataKeys, searchParams, setSearchParams])

  // Update data when selectedDataKeys changes
  useEffect(() => {
    if (selectedDataKeys.length === 1) {
      const schoolData = dataMap[selectedDataKeys[0] as keyof typeof dataMap];
      
      const schoolName = DATA_OPTIONS[selectedDataKeys[0] as keyof typeof DATA_OPTIONS];
      setData({
        ...schoolData,
        classes: schoolData.classes.map(c => ({
          ...c,
          schoolName
        })),
        students: schoolData.students.map(s => ({
          ...s,
          schoolName
        }))
      });
      return;
    }

    // Merge data from multiple schools
    const mergedData = selectedDataKeys.reduce((acc, school) => {
      const schoolData = dataMap[school as keyof typeof dataMap];
      if (!schoolData) return acc;

      const schoolName = DATA_OPTIONS[school as keyof typeof DATA_OPTIONS];
      
      if (!acc) return {
        ...schoolData,
        classes: schoolData.classes.map(c => ({
          ...c,
          schoolName
        })),
        students: schoolData.students.map(s => ({
          ...s,
          schoolName
        }))
      };

      return {
        ...acc,
        classes: [
          ...acc.classes,
          ...schoolData.classes.map(c => ({
            ...c,
            schoolName
          }))
        ],
        students: [
          ...acc.students,
          ...schoolData.students.map(s => ({
            ...s,
            schoolName
          }))
        ],
        scores: [...acc.scores, ...schoolData.scores],
        studentWritingPieces: [...acc.studentWritingPieces, ...schoolData.studentWritingPieces],
      };
    }, null as KS2Data | null);

    setData(mergedData);
  }, [selectedDataKeys]);

  if (!data) return <Box>Loading...</Box>

  return <KS2TeacherReportContent 
    data={data}
    currentDataKey={selectedDataKeys[0]}
    canChangeData={canChangeData}
    selectedDataKeys={selectedDataKeys}
    setSelectedDataKeys={setSelectedDataKeys}
  />
}

import React from 'react';
import { Box, Chip, Button, SxProps, Theme } from '@mui/material';

interface ChipFilterProps {
  label: string;
  items: { id: string, label: string }[];
  selectedItems: string[];
  onToggle: (name: string) => void;
  onSelectAll: () => void;
  onClearSelection: () => void;
  sx?: SxProps<Theme>;
  vertical?: boolean;
  disabledItems?: string[];
  preventClearAll?: boolean;
}

const ChipFilter: React.FC<ChipFilterProps> = ({
  label,
  items,
  selectedItems,
  onToggle,
  onSelectAll,
  onClearSelection,
  sx,
  vertical = false,
  disabledItems = [],
  preventClearAll = false
}) => {
  const allSelected = selectedItems.length === items.filter(item => !disabledItems.includes(item.id)).length;
  const noneSelected = selectedItems.length === 0;
  const showClearButton = !preventClearAll && selectedItems.length > 1;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <label>{label}</label>
      <Box sx={{
        display: 'flex',
        flexDirection: vertical ? 'column' : 'row',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        height: vertical ? '100%' : '120px',
        overflow: 'hidden',
      }}>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexWrap: 'wrap',
          gap: vertical ? '6px' : '8px', 
          padding: '8px',
          overflowY: 'auto',
          alignContent: 'flex-start',
        }}>
          {items.map((item) => (
            <Chip
              key={item.id}
              label={item.label}
              onClick={() => !disabledItems.includes(item.id) && onToggle(item.id)}
              color={selectedItems.includes(item.id) ? "primary" : "default"}
              disabled={disabledItems.includes(item.id)}
              size="small"
              sx={{
                fontSize: '0.75rem',
                height: '26px',
                margin: 0,
                transition: 'none',
                boxShadow: 'none',
                '&:hover, &:focus, &:active': { boxShadow: 'none' },
              }}
            />
          ))}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: vertical ? 'row' : 'column',
          borderLeft: vertical ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
          borderTop: vertical ? '1px solid rgba(0, 0, 0, 0.23)' : 'none',
          padding: '4px',
          gap: '8px',
          flexGrow: 0,
        }}>
          <Button 
            size="small" 
            variant="outlined" 
            onClick={onSelectAll} 
            disabled={allSelected}
            sx={{ 
              height: '24px', 
              width: vertical ? '50%' : '100px', 
              fontSize: '0.7rem',
              marginRight: vertical ? '4px' : '0',
            }}
          >
            Select All
          </Button>
          {showClearButton &&
            <Button 
              size="small" 
              variant="outlined" 
              onClick={onClearSelection} 
              disabled={noneSelected || (preventClearAll && selectedItems.length <= 1)}
              sx={{ 
                height: '24px', 
                width: vertical ? '50%' : '100px', 
                fontSize: '0.7rem',
                marginLeft: vertical ? '4px' : '0',
              }}
            >
              Clear
            </Button>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default ChipFilter;
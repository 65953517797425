import { WritingPiece, Strand, Topic, Level, KS2Criteria, StylusCriteria } from "../types";
export const strands: Strand[] = [
    { id: "1", name: "Grammar and Punctuation" },
    { id: "2", name: "Composition" },
    { id: "3", name: "Transcription" },
];

export const topics: Topic[] = [
    { id: "1", name: "Sentences", strandId: "1" },
    { id: "2", name: "Punctuation", strandId: "1" },
    { id: "3", name: "Grammatical choices", strandId: "1" },
    { id: "4", name: "Organisation", strandId: "2" },
    { id: "5", name: "Cohesion", strandId: "2" },
    { id: "6", name: "Writer's choices", strandId: "2" },
    { id: "7", name: "Capitalisation", strandId: "3" },
    { id: "8", name: "Handwriting", strandId: "3" },
    { id: "9", name: "Spelling", strandId: "3" },
];

export const levels: Level[] = [
    { id: "1", name: "Working towards the expected standard" },
    { id: "2", name: "Working at the expected standard" },
    { id: "3", name: "Working at greater depth" }
];

export const ks2_criteria: KS2Criteria[] = [
    { id: "1", name: "In narratives, describe settings and characters", levelId: "1" },
    { id: "2", name: "In non-narrative writing, use simple devices to structure the writing and support the reader", levelId: "1" },
    { id: "3", name: "Use capital letters, full stops, question marks, commas for lists and apostrophes for contraction mostly correctly", levelId: "1" },
    { id: "4", name: "Use paragraphs to organise ideas", levelId: "1" },
    { id: "5", name: "In narratives, describe settings, characters and atmosphere ", levelId: "2" },
    { id: "6", name: "Integrate dialogue in narratives to convey character and advance the action", levelId: "2" },
    { id: "7", name: "Select vocabulary and grammatical structures that reflect what the writing requires, doing this mostly appropriately", levelId: "2" },
    { id: "8", name: "Use a range of devices to build cohesion within and across paragraphs", levelId: "2" },
    { id: "9", name: "Use the range of punctuation taught at key stage 2 mostly correctly", levelId: "2" },
    { id: "10", name: "Use verb tenses consistently and correctly throughout their writing", levelId: "2" },
    { id: "11", name: "Distinguish between the language of speech and writing and choose the appropriate register", levelId: "3" },
    { id: "12", name: "Use the range of punctuation taught at key stage 2 correctly and, when necessary, use such punctuation precisely to enhance meaning and avoid ambiguity", levelId: "3" },
];

export const stylus_criteria: StylusCriteria[] = [
    { id: "1", name: "Characters", topicId: "6", ks2CriteriaId: "1", possibleScores: [0, 1, 2, 3], confidence: "" },
    { id: "2", name: "Paragraphs", topicId: "4", ks2CriteriaId: "4", possibleScores: [0, 1, 2, 3], confidence: "3" },
    { id: "3", name: "Settings", topicId: "6", ks2CriteriaId: "1", possibleScores: [0, 1, 2, 3], confidence: "" },
    { id: "4", name: "Atmosphere", topicId: "6", ks2CriteriaId: "5", possibleScores: [0, 1, 2], confidence: "" },
    { id: "5", name: "Show clear changes between language of speech and language of narration within a piece", topicId: "6", ks2CriteriaId: "11", possibleScores: [0, 1], confidence: "" },
    { id: "6", name: "Use colloqiual language or contractions in dialogue", topicId: "6", ks2CriteriaId: "6", possibleScores: [0, 1, 2], confidence: "" },
    { id: "7", name: "Dialogue", topicId: "6", ks2CriteriaId: "6", possibleScores: [0, 1, 2, 3, 4, 5], confidence: "" },
    { id: "8", name: "Use formal language when required for audience or purpose", topicId: "6", ks2CriteriaId: "7", possibleScores: [0, 1, 2], confidence: "" },
    { id: "9", name: "Accurately uses 'and', 'but' or 'so' to join clauses in compound sentences", topicId: "1", ks2CriteriaId: "7", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "10", name: "Subheading", topicId: "4", ks2CriteriaId: "2", possibleScores: [0, 1], confidence: "" },
    { id: "11", name: "Lists", topicId: "4", ks2CriteriaId: "2", possibleScores: [0, 1], confidence: "" },
    { id: "12", name: "Use of inverted commas to indicate direct speech", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2, 3], confidence: "" },
    { id: "13", name: "Attempts to use subordinating conjunctions 'because', 'when' or 'although' in complex sentences", topicId: "1", ks2CriteriaId: "7", possibleScores: [0, 1, 2], confidence: "4" },
    { id: "14", name: "Attempts to use a range of subordinating conjunctions in complex sentences", topicId: "1", ks2CriteriaId: "7", possibleScores: [0, 1, 2], confidence: "2" },
    { id: "16", name: "Adverbials of place", topicId: "5", ks2CriteriaId: "8", possibleScores: [0, 1], confidence: "3" },
    { id: "17", name: "Both capital letters and full stops for sentence demarcation", topicId: "2", ks2CriteriaId: "3", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "18", name: "Use semi-colons to separate clauses within a sentence", topicId: "2", ks2CriteriaId: "912", possibleScores: [0, 1, 2], confidence: "4" },
    { id: "19", name: "Synonyms", topicId: "6", ks2CriteriaId: "8", possibleScores: [0, 1], confidence: "1" },
    { id: "20", name: "Maintain appropriate tense for the task", topicId: "3", ks2CriteriaId: "10", possibleScores: [0, 1, 2], confidence: "2" },
    { id: "21", name: "Use commas to separate items in a list", topicId: "2", ks2CriteriaId: "3", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "22", name: "Use dashes to separate clauses within a sentence", topicId: "2", ks2CriteriaId: "912", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "23", name: "Use commas after adverbial phrases at the beginning of a sentence", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2], confidence: "2" },
    { id: "24", name: "Adverbials of time", topicId: "5", ks2CriteriaId: "8", possibleScores: [0, 1], confidence: "4" },
    { id: "25", name: "Use apostrophes for contractions", topicId: "2", ks2CriteriaId: "3", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "26", name: "Use commas to separate a subordinate clause at the start of a sentence and a main clause", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2], confidence: "1" },
    { id: "27", name: "Capital letters to begin sentences", topicId: "7", ks2CriteriaId: "3", possibleScores: [0, 1, 2], confidence: "4" },
    { id: "28", name: "Use punctuation to deliberately create effects e.g. ellipsis to show an unfinished thought", topicId: "2", ks2CriteriaId: "12", possibleScores: [0, 1], confidence: "2" },
    { id: "29", name: "Use pronouns within or across paragraphs to avoid repetition of proper or common nouns while maintaining clarity", topicId: "5", ks2CriteriaId: "8", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "30", name: "Use adverbial phrases at the end of a sentence", topicId: "1", ks2CriteriaId: "8", possibleScores: [0, 1, 2], confidence: "2" },
    { id: "31", name: "Capital letters for proper nouns", topicId: "7", ks2CriteriaId: "3", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "32", name: "Use colons to introduce a list", topicId: "2", ks2CriteriaId: "12", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "33", name: "Write the appropriate stop marks for questions and statements", topicId: "2", ks2CriteriaId: "3", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "34", name: "Use commas to separate a subordinate clause in the middle of a sentence", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2], confidence: "2" },
    { id: "35", name: "Use hyphens", topicId: "2", ks2CriteriaId: "12", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "36", name: "Accurate use of exclamation marks", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2], confidence: "3" },
    { id: "37", name: "Varied sentence lengths", topicId: "1", ks2CriteriaId: "7", possibleScores: [0, 1, 2, 3], confidence: "3" },
    { id: "38", name: "Parentheses (brackets, commas, dashes)", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2], confidence: "1" },
    { id: "39", name: "Apostrophes to show possession", topicId: "2", ks2CriteriaId: "9", possibleScores: [0, 1, 2], confidence: "4" },
    { id: "40", name: "Modal verbs", topicId: "3", ks2CriteriaId: "7", possibleScores: [0, 1, 2], confidence: "1" },
    { id: "41", name: "Passive voice", topicId: "3", ks2CriteriaId: "7", possibleScores: [0, 1, 2], confidence: "2" }
];

export const writingPieces: WritingPiece[] = [
    { id: "1", name: "If I was...", stylusCriteriaIds: ["2", "3", "4", "8", "9", "13", "14", "16", "17", "18", "19", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "34", "35", "36", "37", "38", "39", "41"] },
    { id: "2", name: "Narrative", stylusCriteriaIds: ["1", "2", "3", "4", "5", "6", "7", "9", "12", "13", "14", "16", "17", "18", "19", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "34", "35", "36", "37", "38", "39", "41"] },
    { id: "3", name: "Diary entry", stylusCriteriaIds: ["2", "3", "4", "5", "6", "7", "9", "12", "13", "14", "16", "17", "18", "19", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "34", "35", "36", "37", "38", "39", "41"] },
    { id: "4", name: "Formal letter", stylusCriteriaIds: ["1", "2", "3", "4", "8", "9", "11", "13", "14", "16", "17", "18", "19", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "34", "35", "36", "37", "38", "39", "41"] },
    { id: "5", name: "Informative", stylusCriteriaIds: ["1", "2", "3", "4", "8", "9", "11", "13", "14", "16", "17", "18", "19", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "34", "35", "36", "37", "38", "39", "41"] },
    { id: "6", name: "Informal Letter", stylusCriteriaIds: ["2", "27", "31", "17", "33", "21", "25", "14", "24", "16", "29", "19", "20", "23", "30", "26", "34", "22", "18", "32", "35", "28", "36", "38", "39", "37", "9", "13", "40", "41"] },
];

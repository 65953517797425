import { WritingPiece, KS2Data, Class, Student, StylusCriteriaScore, Strand, Topic, Level, KS2Criteria, StylusCriteria, StudentWritingPiece, Scan } from "../types";
import { stylus_criteria } from "./ks2-data";

const classes: Class[] = [
    { id: "11", name: "Class A" },
];

const students: Student[] = [
    {id: "recrOX0Ay11hZSH63", name: "Sienna C", classId: "11"},
{id: "recNdPEP4qi2XLcW2", name: "Tia S", classId: "11"},
{id: "recIHBYzWN0uQiXQw", name: "Muhammed D", classId: "11"},
{id: "recIG9yHlX0Xwxy7y", name: "Senudi W", classId: "11"},
{id: "recCa15dDdvk0Zk5e", name: "Oscar T", classId: "11"},
];

const studentWritingPieces: StudentWritingPiece[] = [
    {id: "6479", studentId: "recrOX0Ay11hZSH63", writingPieceId: "2", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6479_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6479_Page_02.jpg"}]},
{id: "6480", studentId: "recNdPEP4qi2XLcW2", writingPieceId: "2", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6480_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6480_Page_02.jpg"}]},
{id: "6481", studentId: "recIHBYzWN0uQiXQw", writingPieceId: "2", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6481_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6481_Page_02.jpg"}]},
{id: "6482", studentId: "recIG9yHlX0Xwxy7y", writingPieceId: "2", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6482_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6482_Page_02.jpg"}]},
{id: "6483", studentId: "recCa15dDdvk0Zk5e", writingPieceId: "2", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6483_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6483_Page_02.jpg"}]},
];

const scores: StylusCriteriaScore[] = [
    {id: "1", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "2", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "3", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "3", score: 0, examples: {negative: [], positive: []}},
{id: "4", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "4", score: 0, examples: {negative: [], positive: []}},
{id: "5", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "6", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "7", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "7", score: 0, examples: {negative: [], positive: []}},
{id: "8", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "9", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "10", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "11", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "12", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "13", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "14", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "15", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "16", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "17", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "18", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "19", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "20", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "21", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "22", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "23", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "24", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "25", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "29", score: 0, examples: {negative: [], positive: []}},
{id: "26", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "27", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "28", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "29", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "30", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "31", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "32", studentId: "recrOX0Ay11hZSH63", studentWritingPieceId: "6479", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "33", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "34", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "35", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "3", score: 0, examples: {negative: [], positive: []}},
{id: "36", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "4", score: 0, examples: {negative: [], positive: []}},
{id: "37", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "38", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "39", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "7", score: 0, examples: {negative: [], positive: []}},
{id: "40", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "41", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "42", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "43", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "44", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "45", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "46", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "47", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "48", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "49", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "50", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "51", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "52", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "53", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "54", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "55", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "56", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "57", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "29", score: 0, examples: {negative: [], positive: []}},
{id: "58", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "59", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "60", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "61", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "62", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "63", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "64", studentId: "recNdPEP4qi2XLcW2", studentWritingPieceId: "6480", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "65", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "66", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "67", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "3", score: 0, examples: {negative: [], positive: []}},
{id: "68", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "69", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "70", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "71", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "7", score: 0, examples: {negative: [], positive: []}},
{id: "72", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "73", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "74", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "75", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "76", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "77", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "78", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "79", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "80", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "81", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "82", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "83", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "84", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "85", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "86", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "87", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "88", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "89", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "90", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "91", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "92", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "93", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "94", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "95", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "96", studentId: "recIHBYzWN0uQiXQw", studentWritingPieceId: "6481", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "97", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "98", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "99", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "3", score: 3, examples: {negative: [], positive: []}},
{id: "100", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "101", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "102", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "103", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "7", score: 1, examples: {negative: [], positive: []}},
{id: "104", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "105", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "106", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "107", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "108", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "109", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "110", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "111", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "112", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "20", score: 2, examples: {negative: [], positive: []}},
{id: "113", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "114", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "115", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "116", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "117", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "118", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "119", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "120", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "28", score: 2, examples: {negative: [], positive: []}},
{id: "121", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "122", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "123", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "31", score: 0, examples: {negative: [], positive: []}},
{id: "124", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "125", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "126", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "127", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "128", studentId: "recIG9yHlX0Xwxy7y", studentWritingPieceId: "6482", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "129", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "130", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "131", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "132", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "133", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "134", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "135", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "7", score: 1, examples: {negative: [], positive: []}},
{id: "136", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "137", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "138", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "139", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "140", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "141", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "142", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "18", score: 1, examples: {negative: [], positive: []}},
{id: "143", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "144", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "145", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "146", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "147", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "148", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "149", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "150", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "151", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "152", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "28", score: 2, examples: {negative: [], positive: []}},
{id: "153", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "29", score: 3, examples: {negative: [], positive: []}},
{id: "154", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "155", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "156", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "157", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "158", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "159", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "160", studentId: "recCa15dDdvk0Zk5e", studentWritingPieceId: "6483", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
].map(score => {
    const possibleScores = stylus_criteria.find(c => c.id === score.criteriaId)?.possibleScores;
    return {
      ...score,
      score: score.score / (possibleScores?.[possibleScores.length - 1] ?? 1)
    }
});

export default { classes, students, studentWritingPieces, scores }

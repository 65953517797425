import React, { ReactNode } from 'react'
import { Typography, Box, Tooltip } from '@mui/material'
import BaseDialogOnePage from '../BaseDialogOnePage'
import ConfidenceCell from './ConfidenceCell'
import { Student, KS2Criteria, Topic, Level, Strand, StylusCriteria, StylusCriteriaScore, StudentWritingPiece, WritingPiece } from '../../types'
import { ks2_criteria, topics, levels, strands } from '../../data/ks2-data'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface DetailDialogProps {
  open: boolean
  onClose: () => void
  student: Student | null
  curricItem: KS2Criteria | Topic | Level | Strand | null
  curricItemType: 'level' | 'strand' | 'ks2Criteria' | 'topic' | null
  stylusCriteria: StylusCriteria[]
  scores: StylusCriteriaScore[]
  studentWritingPieces: StudentWritingPiece[]
  writingPieces: WritingPiece[]
  groupBy: 'ks2Criteria' | 'strands'
  students: Student[]
  type: 'student' | 'parent' | 'child' | 'student-child'
  selectedClassId?: string
  className?: string
}

function isKS2Criteria(criteria: KS2Criteria | Topic | null): criteria is KS2Criteria {
  return criteria !== null && 'levelId' in criteria;
}

function isTopic(criteria: KS2Criteria | Topic | null): criteria is Topic {
  return criteria !== null && 'strandId' in criteria;
}

function isLevel(item: KS2Criteria | Topic | Level | Strand | null): item is Level {
  return item !== null && 'type' in item && item.type === 'level';
}

function isStrand(item: KS2Criteria | Topic | Level | Strand | null): item is Strand {
  return item !== null && 'type' in item && item.type === 'strand';
}

const getConfidenceDescription = (level: number) => {
  switch (level) {
    case 1: return "AI Confidence: Baseline"
    case 2: return "AI Confidence: Developing"
    case 3: return "AI Confidence: Strong"
    case 4: return "AI Confidence: Very Strong"
    default: return "No AI confidence level set"
  }
}

export default function DetailDialog({
  open,
  onClose,
  student,
  curricItem,
  curricItemType,
  stylusCriteria,
  scores,
  studentWritingPieces,
  writingPieces,
  groupBy,
  students,
  type,
  className,
  selectedClassId,
}: DetailDialogProps) {
  const isClassData = scores.some(score => score.id.startsWith('avg-'));

  const relevantStylusCriteria = stylusCriteria.filter(sc => {
    if (isClassData) return true;
    if (type === 'student') return true;
    if (type === 'parent') {
      if (curricItemType === 'level') {
        return ks2_criteria.some(c => c.levelId === curricItem?.id && c.id === sc.ks2CriteriaId);
      }
      if (curricItemType === 'strand') {
        return topics.some(t => t.strandId === curricItem?.id && t.id === sc.topicId);
      }
      return false;
    }
    return groupBy === 'ks2Criteria' 
      ? sc.ks2CriteriaId === curricItem?.id
      : sc.topicId === curricItem?.id;
  });

  const relevantScores = scores.filter(score => {
    if (isClassData) return true;
    return relevantStylusCriteria.some(sc => sc.id === score.criteriaId) &&
      (type === 'parent' || type === 'child' || score.studentId === student?.id);
  });

  const relevantStudentWritingPieces = isClassData
    ? studentWritingPieces.filter(swp => 
        students.find(s => s.id === swp.studentId)?.classId === selectedClassId
      )
    : studentWritingPieces.filter(swp => 
        relevantScores.some(score => score.studentWritingPieceId === swp.id)
      )

  const relevantWritingPieces = writingPieces.filter(wp => 
    relevantStudentWritingPieces.some(swp => swp.writingPieceId === wp.id)
  )

  const parentItemName = curricItemType === 'level' || curricItemType === 'strand'
    ? curricItem?.name
    : curricItemType === 'ks2Criteria'
      ? levels.find(l => l.id === (curricItem as KS2Criteria)?.levelId)?.name
      : strands.find(s => s.id === (curricItem as Topic)?.strandId)?.name;

  const topSectionItems = [
    isClassData && { 
      label: 'Class', 
      value: className 
    },
    !isClassData && (type === 'student' || type === 'student-child') && { 
      label: 'Student', 
      value: student?.name 
    },
    !isClassData && type === 'parent' && { 
      label: groupBy === 'ks2Criteria' ? 'Level' : 'Strand', 
      value: parentItemName 
    },
    (type === 'child' || type === 'student-child') && { 
      label: groupBy === 'ks2Criteria' ? 'KS2 Criteria' : 'Topic', 
      value: curricItem?.name 
    },
  ].filter(Boolean);

  const scans = relevantStudentWritingPieces
    .filter(swp => 
      isClassData
        ? students.find(s => s.id === swp.studentId)?.classId === selectedClassId
        : type === 'parent' || type === 'child' || swp.studentId === student?.id
    )
    .map(swp => swp.scans ?? [])
    .filter(scanArray => scanArray.length > 0)

  const writingPieceGroupedScores = isClassData ? [] : relevantStudentWritingPieces.map(swp => {
    const writingPiece = relevantWritingPieces.find(wp => wp.id === swp.writingPieceId)
    return {
      studentWritingPiece: swp,
      writingPiece,
      scores: relevantScores.filter(score => score.studentWritingPieceId === swp.id)
    }
  })

  return (
    <BaseDialogOnePage
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      topSectionItems={topSectionItems as { label: string; value: ReactNode }[]}
      scans={scans}
    >
      <Typography variant="h5" mb={6}>
        {isClassData ? 'Class Average Confidence Scores' : 'Confidence Scores'}
      </Typography>
      {isClassData ? (
        <Box>
          {scores.map(score => {
            const criteria = stylusCriteria.find(sc => sc.id === score.criteriaId)
            const cellData = {
              negative: 0,
              positive: 0,
              averageScore: score.score
            }
            return (
              <Box key={score.id} display="flex" alignItems="center" mb={2} pr={2}>
                <Typography variant="body1" style={{ flexGrow: 1 }} pr={2}>
                  {criteria?.name}
                </Typography>
                <ConfidenceCell 
                  cellData={cellData} 
                  isClickable={false} 
                  handleCellClick={() => {}}
                />
                <Tooltip title={getConfidenceDescription(Number(criteria?.confidence) || 0)}>
                  <Box component="span" ml={1} sx={{ display: 'flex', flexDirection: 'column', cursor: 'help' }}>
                    {[...Array(4)].map((_, i) => (
                      <CheckCircleIcon
                        key={i}
                        sx={{
                          fontSize: 16,
                          mt: -0.5,
                          color: i < (Number(criteria?.confidence) || 0) ? 'blue' : 'lightgrey'
                        }}
                      />
                    )).reverse()}
                  </Box>
                </Tooltip>
              </Box>
            )
          })}
        </Box>
      ) : (
        writingPieceGroupedScores.map(({ writingPiece, studentWritingPiece, scores }) => (
          <Box key={studentWritingPiece.id}>
            <Typography variant="h6" mb={2}>
              Writing Piece: {writingPiece?.name} {studentWritingPiece.pieceIx !== undefined ? `(${studentWritingPiece.pieceIx + 1})` : ''} 
              {(type === 'child' || type === 'parent') && ' — ' + students.find(s => s.id === studentWritingPiece.studentId)?.name}
            </Typography>
            {scores.map(score => {
              const criteria = stylusCriteria.find(sc => sc.id === score.criteriaId)
              const cellData = {
                negative: 0,
                positive: 0,
                averageScore: score.score
              }
              return (
                <Box key={score.id} display="flex" alignItems="center" mb={2} pr={2}>
                  <Typography variant="body1" style={{ flexGrow: 1 }} pr={2}>
                    {criteria?.name}
                  </Typography>
                  <ConfidenceCell 
                    cellData={cellData} 
                    isClickable={false} 
                    handleCellClick={() => {}}
                  />
                  <Tooltip title={getConfidenceDescription(Number(criteria?.confidence) || 0)}>
                    <Box component="span" ml={1} sx={{ display: 'flex', flexDirection: 'column', cursor: 'help' }}>
                      {[...Array(4)].map((_, i) => (
                        <CheckCircleIcon
                          key={i}
                          sx={{
                            fontSize: 16,
                            mt: -0.5,
                            color: i < (Number(criteria?.confidence) || 0) ? 'blue' : 'lightgrey'
                          }}
                        />
                      )).reverse()}
                    </Box>
                  </Tooltip>
                </Box>
              )
            })}
          </Box>
        ))
      )}
    </BaseDialogOnePage>
  )
}

import { WritingPiece, KS2Data, Class, Student, StylusCriteriaScore, Strand, Topic, Level, KS2Criteria, StylusCriteria, StudentWritingPiece, Scan } from "../types";
import { stylus_criteria } from "./ks2-data";

const classes: Class[] = [
    { id: "31", name: "Class B" },
];
const students: Student[] = [
    {id: "31", name: "Cameron H", classId: "31"},
    {id: "32", name: "Eleanor M", classId: "31"},
    {id: "33", name: "Rosa L", classId: "31"},
    {id: "34", name: "L'Rae S", classId: "31"},
    {id: "35", name: "Layla L", classId: "31"}

];

const studentWritingPieces: StudentWritingPiece[] = [
    {id: "30", studentId: "31", writingPieceId: "4", scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Cameron+H+1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Cameron+H+2.JPG"}]},
    {id: "31", studentId: "32", writingPieceId: "4", scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Eleanor+M.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Eleanor+M+copy.JPG"}]},
    {id: "32", studentId: "33", writingPieceId: "4", scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Rosa+L+1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Rosa+L+2.JPG"}]},
    {id: "33", studentId: "34", writingPieceId: "5", scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/L'Rae+S.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/L'Rae+S+copy.JPG"}]},
    {id: "34", studentId: "35", writingPieceId: "4", scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Layla+L+1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/Layla+L+2.JPG"}]},
];

const scores: StylusCriteriaScore[] = [
    {id: "1", studentId: "31", studentWritingPieceId: "30", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
    {id: "2", studentId: "31", studentWritingPieceId: "30", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
    {id: "3", studentId: "31", studentWritingPieceId: "30", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "4", studentId: "31", studentWritingPieceId: "30", criteriaId: "10", score: 0, examples: {negative: [], positive: []}},
    {id: "5", studentId: "31", studentWritingPieceId: "30", criteriaId: "11", score: 0, examples: {negative: [], positive: []}},
    {id: "6", studentId: "31", studentWritingPieceId: "30", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
    {id: "7", studentId: "31", studentWritingPieceId: "30", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
    {id: "8", studentId: "31", studentWritingPieceId: "30", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "9", studentId: "31", studentWritingPieceId: "30", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "10", studentId: "31", studentWritingPieceId: "30", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "11", studentId: "31", studentWritingPieceId: "30", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "12", studentId: "31", studentWritingPieceId: "30", criteriaId: "20", score: 2, examples: {negative: [], positive: []}},
    {id: "13", studentId: "31", studentWritingPieceId: "30", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
    {id: "14", studentId: "31", studentWritingPieceId: "30", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "15", studentId: "31", studentWritingPieceId: "30", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
    {id: "16", studentId: "31", studentWritingPieceId: "30", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "17", studentId: "31", studentWritingPieceId: "30", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
    {id: "18", studentId: "31", studentWritingPieceId: "30", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
    {id: "19", studentId: "31", studentWritingPieceId: "30", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
    {id: "20", studentId: "31", studentWritingPieceId: "30", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
    {id: "21", studentId: "31", studentWritingPieceId: "30", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "22", studentId: "31", studentWritingPieceId: "30", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
    {id: "23", studentId: "31", studentWritingPieceId: "30", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
    {id: "24", studentId: "31", studentWritingPieceId: "30", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "25", studentId: "31", studentWritingPieceId: "30", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "26", studentId: "31", studentWritingPieceId: "30", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
    {id: "27", studentId: "31", studentWritingPieceId: "30", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "28", studentId: "31", studentWritingPieceId: "30", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
    {id: "29", studentId: "31", studentWritingPieceId: "30", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "30", studentId: "31", studentWritingPieceId: "30", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "31", studentId: "31", studentWritingPieceId: "30", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "32", studentId: "31", studentWritingPieceId: "30", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
    {id: "33", studentId: "31", studentWritingPieceId: "30", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
    {id: "34", studentId: "32", studentWritingPieceId: "31", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
    {id: "35", studentId: "32", studentWritingPieceId: "31", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
    {id: "36", studentId: "32", studentWritingPieceId: "31", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "37", studentId: "32", studentWritingPieceId: "31", criteriaId: "10", score: 0, examples: {negative: [], positive: []}},
    {id: "38", studentId: "32", studentWritingPieceId: "31", criteriaId: "11", score: 0, examples: {negative: [], positive: []}},
    {id: "39", studentId: "32", studentWritingPieceId: "31", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
    {id: "40", studentId: "32", studentWritingPieceId: "31", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
    {id: "41", studentId: "32", studentWritingPieceId: "31", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "42", studentId: "32", studentWritingPieceId: "31", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "43", studentId: "32", studentWritingPieceId: "31", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "44", studentId: "32", studentWritingPieceId: "31", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "45", studentId: "32", studentWritingPieceId: "31", criteriaId: "20", score: 2, examples: {negative: [], positive: []}},
    {id: "46", studentId: "32", studentWritingPieceId: "31", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
    {id: "47", studentId: "32", studentWritingPieceId: "31", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "48", studentId: "32", studentWritingPieceId: "31", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
    {id: "49", studentId: "32", studentWritingPieceId: "31", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "50", studentId: "32", studentWritingPieceId: "31", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
    {id: "51", studentId: "32", studentWritingPieceId: "31", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
    {id: "52", studentId: "32", studentWritingPieceId: "31", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
    {id: "53", studentId: "32", studentWritingPieceId: "31", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "54", studentId: "32", studentWritingPieceId: "31", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "55", studentId: "32", studentWritingPieceId: "31", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
    {id: "56", studentId: "32", studentWritingPieceId: "31", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "57", studentId: "32", studentWritingPieceId: "31", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "58", studentId: "32", studentWritingPieceId: "31", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "59", studentId: "32", studentWritingPieceId: "31", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "60", studentId: "32", studentWritingPieceId: "31", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "61", studentId: "32", studentWritingPieceId: "31", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
    {id: "62", studentId: "32", studentWritingPieceId: "31", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "63", studentId: "32", studentWritingPieceId: "31", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "64", studentId: "32", studentWritingPieceId: "31", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "65", studentId: "32", studentWritingPieceId: "31", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
    {id: "66", studentId: "32", studentWritingPieceId: "31", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
    {id: "67", studentId: "33", studentWritingPieceId: "32", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
    {id: "68", studentId: "33", studentWritingPieceId: "32", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
    {id: "69", studentId: "33", studentWritingPieceId: "32", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "70", studentId: "33", studentWritingPieceId: "32", criteriaId: "10", score: 0, examples: {negative: [], positive: []}},
    {id: "71", studentId: "33", studentWritingPieceId: "32", criteriaId: "11", score: 0, examples: {negative: [], positive: []}},
    {id: "72", studentId: "33", studentWritingPieceId: "32", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
    {id: "73", studentId: "33", studentWritingPieceId: "32", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
    {id: "74", studentId: "33", studentWritingPieceId: "32", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "75", studentId: "33", studentWritingPieceId: "32", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "76", studentId: "33", studentWritingPieceId: "32", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "77", studentId: "33", studentWritingPieceId: "32", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
    {id: "78", studentId: "33", studentWritingPieceId: "32", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "79", studentId: "33", studentWritingPieceId: "32", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
    {id: "80", studentId: "33", studentWritingPieceId: "32", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "81", studentId: "33", studentWritingPieceId: "32", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
    {id: "82", studentId: "33", studentWritingPieceId: "32", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "83", studentId: "33", studentWritingPieceId: "32", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
    {id: "84", studentId: "33", studentWritingPieceId: "32", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
    {id: "85", studentId: "33", studentWritingPieceId: "32", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
    {id: "86", studentId: "33", studentWritingPieceId: "32", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "87", studentId: "33", studentWritingPieceId: "32", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "88", studentId: "33", studentWritingPieceId: "32", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
    {id: "89", studentId: "33", studentWritingPieceId: "32", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "90", studentId: "33", studentWritingPieceId: "32", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "91", studentId: "33", studentWritingPieceId: "32", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
    {id: "92", studentId: "33", studentWritingPieceId: "32", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "93", studentId: "33", studentWritingPieceId: "32", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "94", studentId: "33", studentWritingPieceId: "32", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "95", studentId: "33", studentWritingPieceId: "32", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "96", studentId: "33", studentWritingPieceId: "32", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "97", studentId: "33", studentWritingPieceId: "32", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "98", studentId: "33", studentWritingPieceId: "32", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
    {id: "99", studentId: "33", studentWritingPieceId: "32", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
    {id: "100", studentId: "34", studentWritingPieceId: "33", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
    {id: "101", studentId: "34", studentWritingPieceId: "33", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
    {id: "102", studentId: "34", studentWritingPieceId: "33", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
    {id: "103", studentId: "34", studentWritingPieceId: "33", criteriaId: "10", score: 1, examples: {negative: [], positive: []}},
    {id: "104", studentId: "34", studentWritingPieceId: "33", criteriaId: "11", score: 0, examples: {negative: [], positive: []}},
    {id: "105", studentId: "34", studentWritingPieceId: "33", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
    {id: "106", studentId: "34", studentWritingPieceId: "33", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
    {id: "107", studentId: "34", studentWritingPieceId: "33", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "108", studentId: "34", studentWritingPieceId: "33", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
    {id: "109", studentId: "34", studentWritingPieceId: "33", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "110", studentId: "34", studentWritingPieceId: "33", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "111", studentId: "34", studentWritingPieceId: "33", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "112", studentId: "34", studentWritingPieceId: "33", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
    {id: "113", studentId: "34", studentWritingPieceId: "33", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "114", studentId: "34", studentWritingPieceId: "33", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
    {id: "115", studentId: "34", studentWritingPieceId: "33", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "116", studentId: "34", studentWritingPieceId: "33", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
    {id: "117", studentId: "34", studentWritingPieceId: "33", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
    {id: "118", studentId: "34", studentWritingPieceId: "33", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
    {id: "119", studentId: "34", studentWritingPieceId: "33", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "120", studentId: "34", studentWritingPieceId: "33", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
    {id: "121", studentId: "34", studentWritingPieceId: "33", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
    {id: "122", studentId: "34", studentWritingPieceId: "33", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
    {id: "123", studentId: "34", studentWritingPieceId: "33", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "124", studentId: "34", studentWritingPieceId: "33", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "125", studentId: "34", studentWritingPieceId: "33", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "126", studentId: "34", studentWritingPieceId: "33", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "127", studentId: "34", studentWritingPieceId: "33", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "128", studentId: "34", studentWritingPieceId: "33", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "129", studentId: "34", studentWritingPieceId: "33", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "130", studentId: "34", studentWritingPieceId: "33", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
    {id: "131", studentId: "34", studentWritingPieceId: "33", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
    {id: "132", studentId: "34", studentWritingPieceId: "33", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
    {id: "133", studentId: "35", studentWritingPieceId: "34", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
    {id: "134", studentId: "35", studentWritingPieceId: "34", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
    {id: "135", studentId: "35", studentWritingPieceId: "34", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "136", studentId: "35", studentWritingPieceId: "34", criteriaId: "10", score: 0, examples: {negative: [], positive: []}},
    {id: "137", studentId: "35", studentWritingPieceId: "34", criteriaId: "11", score: 0, examples: {negative: [], positive: []}},
    {id: "138", studentId: "35", studentWritingPieceId: "34", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
    {id: "139", studentId: "35", studentWritingPieceId: "34", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
    {id: "140", studentId: "35", studentWritingPieceId: "34", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "141", studentId: "35", studentWritingPieceId: "34", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "142", studentId: "35", studentWritingPieceId: "34", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "143", studentId: "35", studentWritingPieceId: "34", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "144", studentId: "35", studentWritingPieceId: "34", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "145", studentId: "35", studentWritingPieceId: "34", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
    {id: "146", studentId: "35", studentWritingPieceId: "34", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "147", studentId: "35", studentWritingPieceId: "34", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
    {id: "148", studentId: "35", studentWritingPieceId: "34", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "149", studentId: "35", studentWritingPieceId: "34", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
    {id: "150", studentId: "35", studentWritingPieceId: "34", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
    {id: "151", studentId: "35", studentWritingPieceId: "34", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
    {id: "152", studentId: "35", studentWritingPieceId: "34", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "153", studentId: "35", studentWritingPieceId: "34", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "154", studentId: "35", studentWritingPieceId: "34", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
    {id: "155", studentId: "35", studentWritingPieceId: "34", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "156", studentId: "35", studentWritingPieceId: "34", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "157", studentId: "35", studentWritingPieceId: "34", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "158", studentId: "35", studentWritingPieceId: "34", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "159", studentId: "35", studentWritingPieceId: "34", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "160", studentId: "35", studentWritingPieceId: "34", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "161", studentId: "35", studentWritingPieceId: "34", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "162", studentId: "35", studentWritingPieceId: "34", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "163", studentId: "35", studentWritingPieceId: "34", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "164", studentId: "35", studentWritingPieceId: "34", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
    {id: "165", studentId: "35", studentWritingPieceId: "34", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
].map(score => {
    const possibleScores = stylus_criteria.find(c => c.id === score.criteriaId)?.possibleScores;
    return {
      ...score,
      score: score.score / (possibleScores?.[possibleScores.length - 1] ?? 1)
    }
});

export default { classes, students, studentWritingPieces, scores }

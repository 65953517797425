import React from 'react';
import { TableCell, Typography } from "@mui/material";

interface ConfidenceCellProps {
  cellData: { negative: number; positive: number; averageScore: number | null } | undefined;
  isClickable: boolean;
  handleCellClick: () => void;
  maxScore?: number; // New prop for maximum score
}

const ConfidenceCell = React.memo(({ cellData, isClickable, handleCellClick, maxScore = 1 }: ConfidenceCellProps) => {
  const getColor = (score: number | null, isHover: boolean = false) => {
    if (score === null) return 'white';
    const normalizedScore = Math.min(score / maxScore, 1);
    const hue = normalizedScore * 120;
    const saturation = isHover ? '90%' : '80%';
    const lightness = isHover ? '65%' : '75%';
    return `hsl(${hue}, ${saturation}, ${lightness})`;
  };

  const renderConfidenceOrScore = () => {
    if (!cellData) return <Typography>-</Typography>;

    const score = cellData.averageScore !== null ? cellData.averageScore.toFixed(2) : '-';
    const color = getColor(cellData.averageScore);

    return (
      <Typography 
        sx={{ 
          textAlign: 'center', 
          fontSize: '0.875rem',
          lineHeight: '1',
          width: '100%',
          color: score === '-' ? 'text.primary' : color,
        }}
      >
        {score}
      </Typography>
    );
  };

  return (
    <TableCell 
      sx={{ 
        p: 2, 
        cursor: isClickable ? 'pointer' : 'default',
        backgroundColor: cellData ? getColor(cellData.averageScore) : 'white',
        boxShadow: 'none',
        '&:hover': isClickable ? {
          backgroundColor: cellData ? getColor(cellData.averageScore, true) : (theme) => theme.palette.action.hover,
          transition: 'background-color 0.3s',
        } : {}
      }}
      onClick={isClickable ? handleCellClick : undefined}
    >
      {renderConfidenceOrScore()}
    </TableCell>
  );
});

export default ConfidenceCell;

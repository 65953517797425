import { WritingPiece, KS2Data, Class, Student, StylusCriteriaScore, Strand, Topic, Level, KS2Criteria, StylusCriteria, StudentWritingPiece, Scan } from "../types";
import { stylus_criteria } from "./ks2-data";

const classes: Class[] = [
    { id: "41", name: "Class C" },
];

const students: Student[] = [
    {id: "recxJd2tLQEorXXFJ", name: "Ava H", classId: "41"},
{id: "recgeRlB8x6ogiUQW", name: "Breanna A", classId: "41"},
{id: "recspGaWCRnKAopuJ", name: "Isla H", classId: "41"},
{id: "rec6rKeVMyny2jYgh", name: "Baheer A", classId: "41"},
{id: "recBqO3HL8WG6UqUD", name: "Ethan C", classId: "41"},
];

const studentWritingPieces: StudentWritingPiece[] = [
    {id: "6233", studentId: "recxJd2tLQEorXXFJ", writingPieceId: "3", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6233_Page_01.jpg"}]},
{id: "6234", studentId: "recgeRlB8x6ogiUQW", writingPieceId: "3", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6234_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6234_Page_02.jpg"}]},
{id: "6288", studentId: "recspGaWCRnKAopuJ", writingPieceId: "3", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6288_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6288_Page_02.jpg"}]},
{id: "6296", studentId: "rec6rKeVMyny2jYgh", writingPieceId: "3", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6296_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6296_Page_02.jpg"}]},
{id: "6311", studentId: "recBqO3HL8WG6UqUD", writingPieceId: "3", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP6311_Page_01.jpg"}]},
];

const scores: StylusCriteriaScore[] = [
    {id: "1", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "2", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "3", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "4", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
{id: "5", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
{id: "6", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "7", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "8", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "9", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "10", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "11", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "12", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "13", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "14", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "20", score: 2, examples: {negative: [], positive: []}},
{id: "15", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "16", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "17", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "18", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "19", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "20", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "21", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "22", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "23", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "24", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "25", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "26", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "27", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "28", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "29", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "30", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "31", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "32", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "33", studentId: "recxJd2tLQEorXXFJ", studentWritingPieceId: "6233", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "34", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "35", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "36", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "37", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
{id: "38", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "39", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "40", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "41", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "42", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "43", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "44", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "45", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "46", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "47", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "48", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "49", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "50", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "51", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "52", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "53", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "54", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "55", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "56", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "57", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "58", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "59", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "60", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "61", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "62", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
{id: "63", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "64", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "65", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "66", studentId: "recgeRlB8x6ogiUQW", studentWritingPieceId: "6234", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "67", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "68", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "69", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "70", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
{id: "71", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
{id: "72", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "73", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "74", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "75", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "76", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "77", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "78", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "79", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "80", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "81", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "82", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "83", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "84", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "85", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "86", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "87", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "88", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "89", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "90", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "91", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "92", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "93", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "94", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "95", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "96", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "97", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "98", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "99", studentId: "recspGaWCRnKAopuJ", studentWritingPieceId: "6288", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "100", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "101", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "102", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "103", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "104", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
{id: "105", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "106", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "107", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "108", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "109", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "110", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "111", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "112", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "113", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "114", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "115", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "116", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "117", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "118", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "119", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "120", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "121", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "122", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "123", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "124", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "125", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "126", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "127", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "128", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "129", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "130", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "131", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "132", studentId: "rec6rKeVMyny2jYgh", studentWritingPieceId: "6296", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "133", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "134", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "135", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "136", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "137", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "7", score: 0, examples: {negative: [], positive: []}},
{id: "138", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "139", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "140", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "141", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
{id: "142", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "143", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "144", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "145", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "146", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "147", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "148", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "149", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "150", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "151", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "152", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "153", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "154", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "155", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "156", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "157", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "158", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "159", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "160", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "161", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "162", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "163", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "164", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "165", studentId: "recBqO3HL8WG6UqUD", studentWritingPieceId: "6311", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
].map(score => {
    const possibleScores = stylus_criteria.find(c => c.id === score.criteriaId)?.possibleScores;
    return {
      ...score,
      score: score.score / (possibleScores?.[possibleScores.length - 1] ?? 1)
    }
});

export default { classes, students, studentWritingPieces, scores }

import React from 'react';

interface PlaceholderSVGProps {
  width: number;
  height: number;
  currentPage: number;
  onClick: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const PlaceholderSVG: React.FC<PlaceholderSVGProps> = ({ width, height, currentPage, onClick }) => (
  <svg
    width="100%"
    height="100%"
    viewBox={`0 0 ${width} ${height}`}
    onClick={onClick}
  >
    <rect x="0" y="0" width="100%" height="100%" fill="#ffffff" stroke="#000000" strokeWidth="2" />
    {[...Array(12)].map((_, i) => (
      <line 
        key={i} 
        x1={width * 0.1}
        y1={height * (0.12 + i * 0.06)}
        x2={width * 0.9}
        y2={height * (0.12 + i * 0.06)}
        stroke="#cccccc" 
        strokeWidth="1" 
      />
    ))}
    <text x={width / 2} y={height / 2} fontFamily="Arial" fontSize="24" fill="#999999" textAnchor="middle">Scan Placeholder</text>
    <text x={width / 2} y={height * 0.56} fontFamily="Arial" fontSize="18" fill="#999999" textAnchor="middle">Page {currentPage}</text>
  </svg>
);

export default PlaceholderSVG;
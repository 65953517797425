import { WritingPiece, KS2Data, Class, Student, StylusCriteriaScore, Strand, Topic, Level, KS2Criteria, StylusCriteria, StudentWritingPiece, Scan } from "../types";
import { stylus_criteria } from "./ks2-data";

const classes: Class[] = [
    { id: "51", name: "Class D" },
];
const students: Student[] = [
    {id: "51", name: "Molly C", classId: "51"},   
    {id: "52", name: "Oscar P", classId: "51"},
    {id: "53", name: "Scarlett S", classId: "51"}
];

const studentWritingPieces: StudentWritingPiece[] = [
    {id: "50", studentId: "51", writingPieceId: "2", pieceIx: 0, scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/molly_c_1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/molly_c_2.JPG"}]},
    {id: "51", studentId: "52", writingPieceId: "2", pieceIx: 0, scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/oscar_p_1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/oscar_p_2.JPG"}]},
    {id: "52", studentId: "53", writingPieceId: "2", pieceIx: 0, scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/scarlett_s_1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/scarlett_s_2.JPG"}]},
    {id: "1000", studentId: "53", writingPieceId: "2", pieceIx: 1, scans: [{page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/scarlett_s_2_1.JPG"}, {page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/scarlett_s_2_2.JPG"}]},
    {id: "1001", studentId: "51", writingPieceId: "2", pieceIx: 1, scans: [{ page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/molly_c_2_1.JPG" }, { page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/molly_c_2_2.JPG" }]},
    {id: "1002", studentId: "52", writingPieceId: "2", pieceIx: 1, scans: [{ page: 1, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/oscar_p_2_1.JPG" }, { page: 2, url: "https://stylus-dev-misc-assets.s3.eu-west-1.amazonaws.com/ks2-tool-prototype/oscar_p_2_2.JPG" }]},
];

const scores: StylusCriteriaScore[] = [
    {id: "1", studentId: "51", studentWritingPieceId: "50", criteriaId: "1", score: 1, examples: {negative: [], positive: []}},
    {id: "2", studentId: "51", studentWritingPieceId: "50", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
    {id: "3", studentId: "51", studentWritingPieceId: "50", criteriaId: "3", score: 1, examples: {negative: [], positive: []}},
    {id: "4", studentId: "51", studentWritingPieceId: "50", criteriaId: "4", score: 0, examples: {negative: [], positive: []}},
    {id: "5", studentId: "51", studentWritingPieceId: "50", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
    {id: "6", studentId: "51", studentWritingPieceId: "50", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
    {id: "7", studentId: "51", studentWritingPieceId: "50", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
    {id: "8", studentId: "51", studentWritingPieceId: "50", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
    {id: "9", studentId: "51", studentWritingPieceId: "50", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
    {id: "10", studentId: "51", studentWritingPieceId: "50", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
    {id: "11", studentId: "51", studentWritingPieceId: "50", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
    {id: "12", studentId: "51", studentWritingPieceId: "50", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "13", studentId: "51", studentWritingPieceId: "50", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "14", studentId: "51", studentWritingPieceId: "50", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "15", studentId: "51", studentWritingPieceId: "50", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "16", studentId: "51", studentWritingPieceId: "50", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "17", studentId: "51", studentWritingPieceId: "50", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
    {id: "18", studentId: "51", studentWritingPieceId: "50", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "19", studentId: "51", studentWritingPieceId: "50", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
    {id: "20", studentId: "51", studentWritingPieceId: "50", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "21", studentId: "51", studentWritingPieceId: "50", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
    {id: "22", studentId: "51", studentWritingPieceId: "50", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
    {id: "23", studentId: "51", studentWritingPieceId: "50", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
    {id: "24", studentId: "51", studentWritingPieceId: "50", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "25", studentId: "51", studentWritingPieceId: "50", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "26", studentId: "51", studentWritingPieceId: "50", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
    {id: "27", studentId: "51", studentWritingPieceId: "50", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "28", studentId: "51", studentWritingPieceId: "50", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "29", studentId: "51", studentWritingPieceId: "50", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "30", studentId: "51", studentWritingPieceId: "50", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "31", studentId: "51", studentWritingPieceId: "50", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "32", studentId: "51", studentWritingPieceId: "50", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "33", studentId: "51", studentWritingPieceId: "50", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "34", studentId: "51", studentWritingPieceId: "50", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "35", studentId: "51", studentWritingPieceId: "50", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "36", studentId: "52", studentWritingPieceId: "51", criteriaId: "1", score: 1, examples: {negative: [], positive: []}},
    {id: "37", studentId: "52", studentWritingPieceId: "51", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
    {id: "38", studentId: "52", studentWritingPieceId: "51", criteriaId: "3", score: 3, examples: {negative: [], positive: []}},
    {id: "39", studentId: "52", studentWritingPieceId: "51", criteriaId: "4", score: 2, examples: {negative: [], positive: []}},
    {id: "40", studentId: "52", studentWritingPieceId: "51", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
    {id: "41", studentId: "52", studentWritingPieceId: "51", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
    {id: "42", studentId: "52", studentWritingPieceId: "51", criteriaId: "7", score: 4, examples: {negative: [], positive: []}},
    {id: "43", studentId: "52", studentWritingPieceId: "51", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
    {id: "44", studentId: "52", studentWritingPieceId: "51", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
    {id: "45", studentId: "52", studentWritingPieceId: "51", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
    {id: "46", studentId: "52", studentWritingPieceId: "51", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
    {id: "47", studentId: "52", studentWritingPieceId: "51", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "48", studentId: "52", studentWritingPieceId: "51", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
    {id: "49", studentId: "52", studentWritingPieceId: "51", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "50", studentId: "52", studentWritingPieceId: "51", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "51", studentId: "52", studentWritingPieceId: "51", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "52", studentId: "52", studentWritingPieceId: "51", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
    {id: "53", studentId: "52", studentWritingPieceId: "51", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "54", studentId: "52", studentWritingPieceId: "51", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
    {id: "55", studentId: "52", studentWritingPieceId: "51", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "56", studentId: "52", studentWritingPieceId: "51", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
    {id: "57", studentId: "52", studentWritingPieceId: "51", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
    {id: "58", studentId: "52", studentWritingPieceId: "51", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
    {id: "59", studentId: "52", studentWritingPieceId: "51", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "60", studentId: "52", studentWritingPieceId: "51", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
    {id: "61", studentId: "52", studentWritingPieceId: "51", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
    {id: "62", studentId: "52", studentWritingPieceId: "51", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "63", studentId: "52", studentWritingPieceId: "51", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "64", studentId: "52", studentWritingPieceId: "51", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
    {id: "65", studentId: "52", studentWritingPieceId: "51", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
    {id: "66", studentId: "52", studentWritingPieceId: "51", criteriaId: "35", score: 2, examples: {negative: [], positive: []}},
    {id: "67", studentId: "52", studentWritingPieceId: "51", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
    {id: "68", studentId: "52", studentWritingPieceId: "51", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "69", studentId: "52", studentWritingPieceId: "51", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "70", studentId: "52", studentWritingPieceId: "51", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "71", studentId: "53", studentWritingPieceId: "52", criteriaId: "1", score: 1, examples: {negative: [], positive: []}},
    {id: "72", studentId: "53", studentWritingPieceId: "52", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
    {id: "73", studentId: "53", studentWritingPieceId: "52", criteriaId: "3", score: 1, examples: {negative: [], positive: []}},
    {id: "74", studentId: "53", studentWritingPieceId: "52", criteriaId: "4", score: 0, examples: {negative: [], positive: []}},
    {id: "75", studentId: "53", studentWritingPieceId: "52", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
    {id: "76", studentId: "53", studentWritingPieceId: "52", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
    {id: "77", studentId: "53", studentWritingPieceId: "52", criteriaId: "7", score: 1, examples: {negative: [], positive: []}},
    {id: "78", studentId: "53", studentWritingPieceId: "52", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "79", studentId: "53", studentWritingPieceId: "52", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
    {id: "80", studentId: "53", studentWritingPieceId: "52", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
    {id: "81", studentId: "53", studentWritingPieceId: "52", criteriaId: "14", score: 0, examples: {negative: [], positive: []}},
    {id: "82", studentId: "53", studentWritingPieceId: "52", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "83", studentId: "53", studentWritingPieceId: "52", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "84", studentId: "53", studentWritingPieceId: "52", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "85", studentId: "53", studentWritingPieceId: "52", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
    {id: "86", studentId: "53", studentWritingPieceId: "52", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "87", studentId: "53", studentWritingPieceId: "52", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
    {id: "88", studentId: "53", studentWritingPieceId: "52", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "89", studentId: "53", studentWritingPieceId: "52", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
    {id: "90", studentId: "53", studentWritingPieceId: "52", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "91", studentId: "53", studentWritingPieceId: "52", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
    {id: "92", studentId: "53", studentWritingPieceId: "52", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
    {id: "93", studentId: "53", studentWritingPieceId: "52", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
    {id: "94", studentId: "53", studentWritingPieceId: "52", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "95", studentId: "53", studentWritingPieceId: "52", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "96", studentId: "53", studentWritingPieceId: "52", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
    {id: "97", studentId: "53", studentWritingPieceId: "52", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "98", studentId: "53", studentWritingPieceId: "52", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "99", studentId: "53", studentWritingPieceId: "52", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
    {id: "100", studentId: "53", studentWritingPieceId: "52", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "101", studentId: "53", studentWritingPieceId: "52", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "102", studentId: "53", studentWritingPieceId: "52", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "103", studentId: "53", studentWritingPieceId: "52", criteriaId: "37", score: 0, examples: {negative: [], positive: []}},
    {id: "104", studentId: "53", studentWritingPieceId: "52", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "105", studentId: "53", studentWritingPieceId: "52", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "106", studentId: "53", studentWritingPieceId: "1000", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
    {id: "107", studentId: "53", studentWritingPieceId: "1000", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
    {id: "108", studentId: "53", studentWritingPieceId: "1000", criteriaId: "3", score: 1, examples: {negative: [], positive: []}},
    {id: "109", studentId: "53", studentWritingPieceId: "1000", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
    {id: "110", studentId: "53", studentWritingPieceId: "1000", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
    {id: "111", studentId: "53", studentWritingPieceId: "1000", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
    {id: "112", studentId: "53", studentWritingPieceId: "1000", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
    {id: "113", studentId: "53", studentWritingPieceId: "1000", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "114", studentId: "53", studentWritingPieceId: "1000", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
    {id: "115", studentId: "53", studentWritingPieceId: "1000", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
    {id: "116", studentId: "53", studentWritingPieceId: "1000", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
    {id: "117", studentId: "53", studentWritingPieceId: "1000", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
    {id: "118", studentId: "53", studentWritingPieceId: "1000", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "119", studentId: "53", studentWritingPieceId: "1000", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "120", studentId: "53", studentWritingPieceId: "1000", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "121", studentId: "53", studentWritingPieceId: "1000", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "122", studentId: "53", studentWritingPieceId: "1000", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
    {id: "123", studentId: "53", studentWritingPieceId: "1000", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "124", studentId: "53", studentWritingPieceId: "1000", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
    {id: "125", studentId: "53", studentWritingPieceId: "1000", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "126", studentId: "53", studentWritingPieceId: "1000", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
    {id: "127", studentId: "53", studentWritingPieceId: "1000", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
    {id: "128", studentId: "53", studentWritingPieceId: "1000", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
    {id: "129", studentId: "53", studentWritingPieceId: "1000", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
    {id: "130", studentId: "53", studentWritingPieceId: "1000", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
    {id: "131", studentId: "53", studentWritingPieceId: "1000", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
    {id: "132", studentId: "53", studentWritingPieceId: "1000", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "133", studentId: "53", studentWritingPieceId: "1000", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
    {id: "134", studentId: "53", studentWritingPieceId: "1000", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
    {id: "135", studentId: "53", studentWritingPieceId: "1000", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "136", studentId: "53", studentWritingPieceId: "1000", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "137", studentId: "53", studentWritingPieceId: "1000", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "138", studentId: "53", studentWritingPieceId: "1000", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
    {id: "139", studentId: "53", studentWritingPieceId: "1000", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "140", studentId: "53", studentWritingPieceId: "1000", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
    {id: "141", studentId: "53", studentWritingPieceId: "1000", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
    {id: "142", studentId: "53", studentWritingPieceId: "1000", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
    {id: "143", studentId: "51", studentWritingPieceId: "1001", criteriaId: "1", score: 3, examples: {negative: [], positive: []}},
    {id: "144", studentId: "51", studentWritingPieceId: "1001", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
    {id: "145", studentId: "51", studentWritingPieceId: "1001", criteriaId: "3", score: 3, examples: {negative: [], positive: []}},
    {id: "146", studentId: "51", studentWritingPieceId: "1001", criteriaId: "4", score: 2, examples: {negative: [], positive: []}},
    {id: "147", studentId: "51", studentWritingPieceId: "1001", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
    {id: "148", studentId: "51", studentWritingPieceId: "1001", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
    {id: "149", studentId: "51", studentWritingPieceId: "1001", criteriaId: "7", score: 4, examples: {negative: [], positive: []}},
    {id: "150", studentId: "51", studentWritingPieceId: "1001", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
    {id: "151", studentId: "51", studentWritingPieceId: "1001", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
    {id: "152", studentId: "51", studentWritingPieceId: "1001", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
    {id: "153", studentId: "51", studentWritingPieceId: "1001", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
    {id: "154", studentId: "51", studentWritingPieceId: "1001", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
    {id: "155", studentId: "51", studentWritingPieceId: "1001", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "156", studentId: "51", studentWritingPieceId: "1001", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "157", studentId: "51", studentWritingPieceId: "1001", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "158", studentId: "51", studentWritingPieceId: "1001", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "159", studentId: "51", studentWritingPieceId: "1001", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
    {id: "160", studentId: "51", studentWritingPieceId: "1001", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
    {id: "161", studentId: "51", studentWritingPieceId: "1001", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
    {id: "162", studentId: "51", studentWritingPieceId: "1001", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "163", studentId: "51", studentWritingPieceId: "1001", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
    {id: "164", studentId: "51", studentWritingPieceId: "1001", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
    {id: "165", studentId: "51", studentWritingPieceId: "1001", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
    {id: "166", studentId: "51", studentWritingPieceId: "1001", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
    {id: "167", studentId: "51", studentWritingPieceId: "1001", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
    {id: "168", studentId: "51", studentWritingPieceId: "1001", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
    {id: "169", studentId: "51", studentWritingPieceId: "1001", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "170", studentId: "51", studentWritingPieceId: "1001", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
    {id: "171", studentId: "51", studentWritingPieceId: "1001", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "172", studentId: "51", studentWritingPieceId: "1001", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
    {id: "173", studentId: "51", studentWritingPieceId: "1001", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "174", studentId: "51", studentWritingPieceId: "1001", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
    {id: "175", studentId: "51", studentWritingPieceId: "1001", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
    {id: "176", studentId: "51", studentWritingPieceId: "1001", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
    {id: "177", studentId: "51", studentWritingPieceId: "1001", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
    {id: "178", studentId: "51", studentWritingPieceId: "1001", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
    {id: "179", studentId: "51", studentWritingPieceId: "1001", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
    {id: "180", studentId: "52", studentWritingPieceId: "1002", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
    {id: "181", studentId: "52", studentWritingPieceId: "1002", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
    {id: "182", studentId: "52", studentWritingPieceId: "1002", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
    {id: "183", studentId: "52", studentWritingPieceId: "1002", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
    {id: "184", studentId: "52", studentWritingPieceId: "1002", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
    {id: "185", studentId: "52", studentWritingPieceId: "1002", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
    {id: "186", studentId: "52", studentWritingPieceId: "1002", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
    {id: "187", studentId: "52", studentWritingPieceId: "1002", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
    {id: "188", studentId: "52", studentWritingPieceId: "1002", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
    {id: "189", studentId: "52", studentWritingPieceId: "1002", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
    {id: "190", studentId: "52", studentWritingPieceId: "1002", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
    {id: "191", studentId: "52", studentWritingPieceId: "1002", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
    {id: "192", studentId: "52", studentWritingPieceId: "1002", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
    {id: "193", studentId: "52", studentWritingPieceId: "1002", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
    {id: "194", studentId: "52", studentWritingPieceId: "1002", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
    {id: "195", studentId: "52", studentWritingPieceId: "1002", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
    {id: "196", studentId: "52", studentWritingPieceId: "1002", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
    {id: "197", studentId: "52", studentWritingPieceId: "1002", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
    {id: "198", studentId: "52", studentWritingPieceId: "1002", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
    {id: "199", studentId: "52", studentWritingPieceId: "1002", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
    {id: "200", studentId: "52", studentWritingPieceId: "1002", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
    {id: "201", studentId: "52", studentWritingPieceId: "1002", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
    {id: "202", studentId: "52", studentWritingPieceId: "1002", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
    {id: "203", studentId: "52", studentWritingPieceId: "1002", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
    {id: "204", studentId: "52", studentWritingPieceId: "1002", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
    {id: "205", studentId: "52", studentWritingPieceId: "1002", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
    {id: "206", studentId: "52", studentWritingPieceId: "1002", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
    {id: "207", studentId: "52", studentWritingPieceId: "1002", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
    {id: "208", studentId: "52", studentWritingPieceId: "1002", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
    {id: "209", studentId: "52", studentWritingPieceId: "1002", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
    {id: "210", studentId: "52", studentWritingPieceId: "1002", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
    {id: "211", studentId: "52", studentWritingPieceId: "1002", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
    {id: "212", studentId: "52", studentWritingPieceId: "1002", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
    {id: "213", studentId: "52", studentWritingPieceId: "1002", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
    {id: "214", studentId: "52", studentWritingPieceId: "1002", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
    {id: "215", studentId: "52", studentWritingPieceId: "1002", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
    {id: "216", studentId: "52", studentWritingPieceId: "1002", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
].map(score => {
    const possibleScores = stylus_criteria.find(c => c.id === score.criteriaId)?.possibleScores;
    return {
      ...score,
      score: score.score / (possibleScores?.[possibleScores.length - 1] ?? 1)
    }
});

export default { classes, students, studentWritingPieces, scores }

import { StylusCriteriaScore } from "./types";

export const calculateConfidence = (scores: StylusCriteriaScore[]): { negative: number, positive: number, averageScore: number | null } => {
    const validScores = scores.filter(score => score.score !== null);
    if (validScores.length === 0) return { negative: 0, positive: 0, averageScore: null };

    const totalNegative = validScores.reduce((sum, score) => sum + score.examples.negative.length, 0);
    const totalPositive = validScores.reduce((sum, score) => sum + score.examples.positive.length, 0);
    const total = totalNegative + totalPositive;

    const averageScore = validScores.reduce((sum, score) => sum + (score.score || 0), 0) / validScores.length;

    return total > 0
        ? { negative: totalNegative / total, positive: totalPositive / total, averageScore }
        : validScores.length > 0
        ? { negative: 0, positive: 0, averageScore}
        : { negative: 0, positive: 0, averageScore: null };
};
import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { Sidebar, ScanList, FilterSection } from '../components';
import { mockData } from '../data';
import { KS2Data, WritingPiece, KS2Criteria, Level, StylusCriteria } from '../types';

const ScanViewer: React.FC = () => {
  const [selectedScans, setSelectedScans] = useState<string[]>([]);
  const [data, setData] = useState<KS2Data | null>(null);
  const [selectedPieces, setSelectedPieces] = useState<WritingPiece[]>([]);
  const [criteriaScoreFilter, setCriteriaScoreFilter] = useState<{
    level: Level | null;
    ks2Criteria: KS2Criteria | null;
    stylusCriteria: StylusCriteria | null;
    minScore: number;
    maxScore: number;
  } | null>(null);

  useEffect(() => {
    setData(mockData);
    setSelectedPieces(mockData.writingPieces);
  }, []);

  const handleCompare = () => {
    console.log('Comparing scans:', selectedScans);
  };

  const allScans = useMemo(() => data?.studentWritingPieces.map(swp => {
    const student = data.students.find(s => s.id === swp.studentId);
    const writingPiece = data.writingPieces.find(wp => wp.id === swp.writingPieceId);
    const studentClass = data.classes.find(c => c.id === student?.classId);
    return {
      id: writingPiece?.id || "",
      name: writingPiece?.name || "",
      stylusCriteriaIds: writingPiece?.stylusCriteriaIds || [],
      swpId: swp.id,
      studentName: student?.name || '',
      pageCount: swp.scans.length,
      class: studentClass?.name || ''
    };
  }) || [], [data]);

  const filteredScans = useMemo(() => {
    if (!data) return [];

    let scans = allScans.filter(scan => 
      selectedPieces.some(piece => piece.id === scan.id)
    );

    if (criteriaScoreFilter?.level || criteriaScoreFilter?.ks2Criteria || criteriaScoreFilter?.stylusCriteria) {
      scans = scans.filter(scan => {
        const relevantScores = data?.scores.filter(s => 
          s.studentWritingPieceId === scan.swpId &&
          (!criteriaScoreFilter.level || data.ks2_criteria.find(c => c.id === s.criteriaId)?.levelId === criteriaScoreFilter.level.id) &&
          (!criteriaScoreFilter.ks2Criteria || s.criteriaId === criteriaScoreFilter.ks2Criteria.id) &&
          (!criteriaScoreFilter.stylusCriteria || s.criteriaId === criteriaScoreFilter.stylusCriteria.id)
        );

        if (!relevantScores?.length) return false;

        const averageScore = relevantScores.reduce((sum, s) => sum + (s.score ?? 0), 0) / relevantScores.length;

        return averageScore >= criteriaScoreFilter.minScore && averageScore <= criteriaScoreFilter.maxScore;
      });
    }

    return scans;
  }, [selectedPieces, allScans, criteriaScoreFilter, data]);

  if (!data) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 68px)' }}>
      <Sidebar
        selectedScans={selectedScans}
        onCompare={handleCompare}
      >
        <FilterSection
          selectedPieces={selectedPieces}
          setSelectedPieces={setSelectedPieces}
          writingPieces={data.writingPieces}
          ks2Criteria={data.ks2_criteria}
          levels={data.levels}
          stylusCriteria={data.stylus_criteria}
          criteriaScoreFilter={criteriaScoreFilter}
          setCriteriaScoreFilter={setCriteriaScoreFilter}
        />
      </Sidebar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <ScanList
          scans={filteredScans}
          selectedScans={selectedScans}
          setSelectedScans={setSelectedScans}
          data={data}
        />
      </Box>
    </Box>
  );
};

export default ScanViewer;

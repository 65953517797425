import { WritingPiece, KS2Data, Class, Student, StylusCriteriaScore, Strand, Topic, Level, KS2Criteria, StylusCriteria, StudentWritingPiece, Scan } from "../types";
import { stylus_criteria } from "./ks2-data";

const classes: Class[] = [
    { id: "1", name: "Class A" },
];

const students: Student[] = [
    {id: "recsQWHroiy77brCh", name: "Oliver J", classId: "1"},
    { id: "recJKUQU442ExgvfV", name: "Mila W", classId: "1" },
    { id: "rec6GPnCO0omYioR4", name: "Joey L", classId: "1" },
    { id: "recdwSxJ2newRhP3T", name: "Alfie P", classId: "1" },
    { id: "recvRsY2meifvobJA", name: "Jamie S", classId: "1" },
    { id: "recTogW5mlqjlwZH2", name: "Thomas S", classId: "1" },
    { id: "rec1rPoubYdmhoOHY", name: "Summer W", classId: "1" },
    { id: "recFtqGRJU89N90JR", name: "Andersson D", classId: "1" },
    { id: "recVJGR26KR5oZmn7", name: "William K", classId: "1" },
    { id: "recMBfvVnQgZTHuYr", name: "Kayleigh W", classId: "1" },
    { id: "reccd5LOW2N54hpdX", name: "Emily K", classId: "1" },
    { id: "recKeAvmWHrnoGZMi", name: "Ellie S", classId: "1" },
    { id: "recOdoLHK4TPWE1Jd", name: "Elouise B", classId: "1" },
    { id: "recKoYD3D43iNqYeX", name: "Vincent G", classId: "1" },
    { id: "recwlIwc4jeUcWKlA", name: "Molly J", classId: "1" },
    { id: "recuycR4JRngk7yjA", name: "Tilly S", classId: "1" },
    {id: "recTz2uqrF90NN45F", name: "Millie-Ava K", classId: "1"},
];

const studentWritingPieces: StudentWritingPiece[] = [
    {id: "5077", studentId: "recsQWHroiy77brCh", writingPieceId: "1", scans: [{page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5077_Page_01.jpg"}, {page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5077_Page_02.jpg"}]},
    { id: "5080", studentId: "recJKUQU442ExgvfV", writingPieceId: "1", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5080_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5080_Page_02.jpg" }] },
    { id: "5084", studentId: "rec6GPnCO0omYioR4", writingPieceId: "1", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5084_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5084_Page_02.jpg" }] },
    { id: "5085", studentId: "recdwSxJ2newRhP3T", writingPieceId: "1", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5085_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5085_Page_02.jpg" }] },
    { id: "5086", studentId: "recvRsY2meifvobJA", writingPieceId: "1", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5086_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5086_Page_02.jpg" }] },
    { id: "5091", studentId: "recTogW5mlqjlwZH2", writingPieceId: "1", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5091_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5091_Page_02.jpg" }] },
    { id: "5110", studentId: "recdwSxJ2newRhP3T", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5110_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5110_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5110_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5110_Page_04.jpg" }] },
    { id: "5112", studentId: "rec1rPoubYdmhoOHY", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5112_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5112_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5112_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5112_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5112_Page_05.jpg" }] },
    { id: "5114", studentId: "recFtqGRJU89N90JR", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5114_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5114_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5114_Page_03.jpg" }] },
    { id: "5116", studentId: "recVJGR26KR5oZmn7", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5116_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5116_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5116_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5116_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5116_Page_05.jpg" }] },
    { id: "5118", studentId: "rec6GPnCO0omYioR4", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_05.jpg" }, { page: 6, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_06.jpg" }, { page: 7, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5118_Page_07.jpg" }] },
    { id: "5119", studentId: "recMBfvVnQgZTHuYr", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5119_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5119_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5119_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5119_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5119_Page_05.jpg" }] },
    { id: "5120", studentId: "reccd5LOW2N54hpdX", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5120_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5120_Page_02.jpg" }] },
    { id: "5121", studentId: "recKeAvmWHrnoGZMi", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5121_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5121_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5121_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5121_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5121_Page_05.jpg" }, { page: 6, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5121_Page_06.jpg" }] },
    { id: "5122", studentId: "recOdoLHK4TPWE1Jd", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5122_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5122_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5122_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5122_Page_04.jpg" }] },
    { id: "5123", studentId: "recJKUQU442ExgvfV", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5123_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5123_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5123_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5123_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5123_Page_05.jpg" }, { page: 6, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5123_Page_06.jpg" }] },
    { id: "5125", studentId: "recsQWHroiy77brCh", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5125_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5125_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5125_Page_03.jpg" }] },
    { id: "5126", studentId: "recKoYD3D43iNqYeX", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5126_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5126_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5126_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5126_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5126_Page_05.jpg" }] },
    { id: "5127", studentId: "recwlIwc4jeUcWKlA", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5127_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5127_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5127_Page_03.jpg" }] },
    { id: "5128", studentId: "recuycR4JRngk7yjA", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5128_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5128_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5128_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5128_Page_04.jpg" }, { page: 5, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5128_Page_05.jpg" }, { page: 6, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5128_Page_06.jpg" }] },
    { id: "5129", studentId: "recTz2uqrF90NN45F", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5129_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5129_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5129_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5129_Page_04.jpg" }] },
    { id: "5130", studentId: "recvRsY2meifvobJA", writingPieceId: "2", scans: [{ page: 1, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5130_Page_01.jpg" }, { page: 2, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5130_Page_02.jpg" }, { page: 3, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5130_Page_03.jpg" }, { page: 4, url: "https://paper-processing-service-dev-paper-processing-bucket.s3.eu-west-1.amazonaws.com/jpgs/PP5130_Page_04.jpg" }] },
];

const scores: StylusCriteriaScore[] = [
    {id: "1", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "2", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
{id: "3", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "4", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "5", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "6", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "7", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "8", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "9", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "10", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "11", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "12", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "13", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "14", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "15", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "16", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "17", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "18", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "19", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "20", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "21", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "22", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "23", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "24", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "25", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5077", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "26", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "27", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "8", score: 2, examples: {negative: [], positive: []}},
{id: "28", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "29", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "30", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "31", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "32", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "33", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "34", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "35", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "36", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "37", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "38", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "39", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "40", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "41", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "42", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "43", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "44", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "45", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "46", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "47", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "48", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "49", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "50", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5080", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "51", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "52", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
{id: "53", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "54", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "55", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "56", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "57", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "58", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "59", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "60", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "61", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "62", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "63", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "64", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "65", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "66", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "67", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "68", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "69", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "70", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "71", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "72", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "73", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "74", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "75", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5084", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "76", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "77", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "8", score: 2, examples: {negative: [], positive: []}},
{id: "78", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "9", score: 0, examples: {negative: [], positive: []}},
{id: "79", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "80", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "81", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "82", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "83", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "84", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "85", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "86", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "87", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "88", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "89", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "90", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "91", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "92", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "93", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "94", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "95", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "96", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "97", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "98", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "99", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "100", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5085", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "101", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "102", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
{id: "103", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "104", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "105", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "106", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "107", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "108", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "109", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "110", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "111", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "112", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "113", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "114", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "115", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "116", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "117", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "118", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "119", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "120", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "121", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "31", score: 0, examples: {negative: [], positive: []}},
{id: "122", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "123", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "124", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "125", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5086", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "126", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "127", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "8", score: 1, examples: {negative: [], positive: []}},
{id: "128", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "129", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "130", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "131", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "132", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "133", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "134", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "135", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "136", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "137", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "138", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "139", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "24", score: 0, examples: {negative: [], positive: []}},
{id: "140", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "141", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "142", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "143", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "144", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "145", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "146", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "31", score: 0, examples: {negative: [], positive: []}},
{id: "147", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "148", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "149", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "150", studentId: "recTogW5mlqjlwZH2", studentWritingPieceId: "5091", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "151", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "152", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "153", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "154", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "155", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "156", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "157", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "7", score: 4, examples: {negative: [], positive: []}},
{id: "158", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "159", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "12", score: 3, examples: {negative: [], positive: []}},
{id: "160", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "161", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "162", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "163", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "164", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "165", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "166", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "167", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "168", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "169", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "170", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "171", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "172", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "173", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "174", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "175", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "176", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "177", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "178", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "179", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "180", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "181", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "182", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "183", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "184", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "185", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "186", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "187", studentId: "recdwSxJ2newRhP3T", studentWritingPieceId: "5110", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "188", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "1", score: 3, examples: {negative: [], positive: []}},
{id: "189", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "190", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "191", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "192", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "193", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "194", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "195", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "196", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "197", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "198", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "199", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "200", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "17", score: 2, examples: {negative: [], positive: []}},
{id: "201", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "202", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "203", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "204", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "205", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "206", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "207", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "208", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "209", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "210", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "211", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "212", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "213", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "214", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "215", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "32", score: 1, examples: {negative: [], positive: []}},
{id: "216", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "217", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "218", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "219", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "36", score: 2, examples: {negative: [], positive: []}},
{id: "220", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "221", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "222", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "223", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "224", studentId: "rec1rPoubYdmhoOHY", studentWritingPieceId: "5112", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "225", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "1", score: 1, examples: {negative: [], positive: []}},
{id: "226", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "227", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "228", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "229", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "230", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "231", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
{id: "232", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "233", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "234", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "235", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "236", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "237", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "238", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "239", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "240", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "241", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "242", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "243", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "244", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "245", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "246", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "247", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "248", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "249", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "250", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "251", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "252", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "253", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "254", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "255", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "256", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "257", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "258", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "259", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "260", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "261", studentId: "recFtqGRJU89N90JR", studentWritingPieceId: "5114", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "262", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "263", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "264", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "265", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "266", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "267", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "268", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "269", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "270", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "271", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "272", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "273", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "274", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "275", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "276", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "277", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "278", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "279", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "280", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "281", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "282", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "283", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "284", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "285", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "286", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "287", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "288", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "289", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "290", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "291", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "292", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "293", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "294", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "295", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "296", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "297", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "298", studentId: "recVJGR26KR5oZmn7", studentWritingPieceId: "5116", criteriaId: "41", score: 1, examples: {negative: [], positive: []}},
{id: "299", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "300", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "301", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "302", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "303", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "304", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "305", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "7", score: 4, examples: {negative: [], positive: []}},
{id: "306", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "307", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "308", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "309", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "310", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "311", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "312", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "313", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "314", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "315", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "316", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "22", score: 1, examples: {negative: [], positive: []}},
{id: "317", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "318", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "319", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "320", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "321", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "322", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "323", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "324", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "325", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "326", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "327", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "328", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "329", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "35", score: 1, examples: {negative: [], positive: []}},
{id: "330", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "331", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "332", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "333", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "334", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "335", studentId: "rec6GPnCO0omYioR4", studentWritingPieceId: "5118", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "336", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "337", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "338", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "339", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "340", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "341", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "342", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "343", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "344", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "12", score: 3, examples: {negative: [], positive: []}},
{id: "345", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "346", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "347", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "348", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "349", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "350", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "351", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "352", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "353", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "354", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "355", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "356", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "357", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "358", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "359", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "360", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "361", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "362", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "363", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "364", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "365", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "34", score: 1, examples: {negative: [], positive: []}},
{id: "366", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "367", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "368", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "369", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "38", score: 1, examples: {negative: [], positive: []}},
{id: "370", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "371", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "372", studentId: "recMBfvVnQgZTHuYr", studentWritingPieceId: "5119", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "373", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "374", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "375", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "376", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "4", score: 2, examples: {negative: [], positive: []}},
{id: "377", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "378", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
{id: "379", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "380", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "381", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "382", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "383", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "384", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "385", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "386", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "387", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "388", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "389", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "390", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "391", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "392", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "393", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "394", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "395", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "396", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "397", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "398", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "399", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "400", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "401", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "33", score: 0, examples: {negative: [], positive: []}},
{id: "402", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "403", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "404", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "405", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "406", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "407", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "408", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "409", studentId: "reccd5LOW2N54hpdX", studentWritingPieceId: "5120", criteriaId: "41", score: 0, examples: {negative: [], positive: []}},
{id: "410", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "1", score: 3, examples: {negative: [], positive: []}},
{id: "411", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "412", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "3", score: 3, examples: {negative: [], positive: []}},
{id: "413", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "4", score: 2, examples: {negative: [], positive: []}},
{id: "414", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "415", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
{id: "416", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "417", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "418", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "419", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "420", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "421", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "422", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "423", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "424", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "425", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "426", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "427", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "428", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "429", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "430", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "25", score: 0, examples: {negative: [], positive: []}},
{id: "431", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "432", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "433", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "434", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "435", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "436", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "31", score: 0, examples: {negative: [], positive: []}},
{id: "437", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "438", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "439", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "440", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "441", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "442", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "443", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "444", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "445", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "446", studentId: "recKeAvmWHrnoGZMi", studentWritingPieceId: "5121", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "447", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "448", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "449", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "450", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "451", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "452", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "453", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "454", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "455", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "12", score: 3, examples: {negative: [], positive: []}},
{id: "456", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "457", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "458", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "459", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "460", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "461", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "462", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "463", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "464", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "465", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "466", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "467", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "468", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "469", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "470", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "471", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "472", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "473", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "474", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "475", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "476", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "477", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "478", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "479", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "480", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "481", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "482", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "483", studentId: "recOdoLHK4TPWE1Jd", studentWritingPieceId: "5122", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "484", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "485", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "486", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "487", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "488", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "489", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "490", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "491", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "492", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "493", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "494", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "495", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "496", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "497", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "498", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "499", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "500", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "501", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "502", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "23", score: 2, examples: {negative: [], positive: []}},
{id: "503", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "504", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "505", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "506", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "507", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "508", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "509", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "510", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "511", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "512", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "513", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "34", score: 2, examples: {negative: [], positive: []}},
{id: "514", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "515", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "516", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "517", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "518", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "519", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "520", studentId: "recJKUQU442ExgvfV", studentWritingPieceId: "5123", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "521", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "522", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "523", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "524", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "525", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "526", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "527", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
{id: "528", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "529", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "530", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "531", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "532", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "533", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "534", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "535", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "536", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "537", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "538", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "539", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "540", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "541", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "542", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "543", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "544", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "545", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "546", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "30", score: 1, examples: {negative: [], positive: []}},
{id: "547", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "548", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "549", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "550", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "551", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "552", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "553", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "554", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "555", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "39", score: 2, examples: {negative: [], positive: []}},
{id: "556", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "557", studentId: "recsQWHroiy77brCh", studentWritingPieceId: "5125", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "558", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "1", score: 3, examples: {negative: [], positive: []}},
{id: "559", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "560", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "561", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "562", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "563", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "564", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "565", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "566", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "567", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "568", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "569", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "570", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "571", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "572", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "573", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "20", score: 2, examples: {negative: [], positive: []}},
{id: "574", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "575", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "22", score: 2, examples: {negative: [], positive: []}},
{id: "576", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "577", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "578", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "579", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "580", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "27", score: 1, examples: {negative: [], positive: []}},
{id: "581", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "582", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "583", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "30", score: 0, examples: {negative: [], positive: []}},
{id: "584", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "31", score: 2, examples: {negative: [], positive: []}},
{id: "585", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "32", score: 2, examples: {negative: [], positive: []}},
{id: "586", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "587", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "588", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "589", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "590", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "591", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "38", score: 2, examples: {negative: [], positive: []}},
{id: "592", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "593", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "594", studentId: "recKoYD3D43iNqYeX", studentWritingPieceId: "5126", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "595", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "1", score: 1, examples: {negative: [], positive: []}},
{id: "596", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "597", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "3", score: 3, examples: {negative: [], positive: []}},
{id: "598", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "599", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "600", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "601", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "7", score: 2, examples: {negative: [], positive: []}},
{id: "602", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "603", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "604", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "605", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "606", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "607", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "608", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "609", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "19", score: 0, examples: {negative: [], positive: []}},
{id: "610", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "611", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "21", score: 2, examples: {negative: [], positive: []}},
{id: "612", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "613", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "614", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "615", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "616", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "26", score: 1, examples: {negative: [], positive: []}},
{id: "617", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "618", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "619", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "620", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "621", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "622", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "623", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "624", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "625", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "626", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "36", score: 1, examples: {negative: [], positive: []}},
{id: "627", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "628", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "629", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "630", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "631", studentId: "recwlIwc4jeUcWKlA", studentWritingPieceId: "5127", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "632", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "633", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "634", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "3", score: 3, examples: {negative: [], positive: []}},
{id: "635", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "4", score: 2, examples: {negative: [], positive: []}},
{id: "636", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "637", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "6", score: 0, examples: {negative: [], positive: []}},
{id: "638", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "639", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "640", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "12", score: 2, examples: {negative: [], positive: []}},
{id: "641", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "13", score: 1, examples: {negative: [], positive: []}},
{id: "642", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "643", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "644", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "645", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "646", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "647", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "648", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "649", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "650", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "23", score: 1, examples: {negative: [], positive: []}},
{id: "651", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "652", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "653", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "26", score: 2, examples: {negative: [], positive: []}},
{id: "654", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "655", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "28", score: 1, examples: {negative: [], positive: []}},
{id: "656", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "29", score: 2, examples: {negative: [], positive: []}},
{id: "657", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "658", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "31", score: 0, examples: {negative: [], positive: []}},
{id: "659", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "660", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "661", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "662", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "663", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "664", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "37", score: 3, examples: {negative: [], positive: []}},
{id: "665", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "666", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "667", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "668", studentId: "recuycR4JRngk7yjA", studentWritingPieceId: "5128", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "669", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "670", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "2", score: 2, examples: {negative: [], positive: []}},
{id: "671", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "672", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "673", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "5", score: 1, examples: {negative: [], positive: []}},
{id: "674", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "6", score: 1, examples: {negative: [], positive: []}},
{id: "675", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "676", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "9", score: 2, examples: {negative: [], positive: []}},
{id: "677", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "12", score: 1, examples: {negative: [], positive: []}},
{id: "678", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "13", score: 0, examples: {negative: [], positive: []}},
{id: "679", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "14", score: 1, examples: {negative: [], positive: []}},
{id: "680", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "16", score: 1, examples: {negative: [], positive: []}},
{id: "681", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "682", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "683", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "684", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "685", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "21", score: 0, examples: {negative: [], positive: []}},
{id: "686", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "687", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "688", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "689", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "25", score: 1, examples: {negative: [], positive: []}},
{id: "690", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "691", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "692", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "693", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "694", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "695", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "696", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "697", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "33", score: 1, examples: {negative: [], positive: []}},
{id: "698", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "699", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "700", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "701", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "37", score: 2, examples: {negative: [], positive: []}},
{id: "702", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "703", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "39", score: 0, examples: {negative: [], positive: []}},
{id: "704", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "40", score: 2, examples: {negative: [], positive: []}},
{id: "705", studentId: "recTz2uqrF90NN45F", studentWritingPieceId: "5129", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
{id: "706", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "1", score: 2, examples: {negative: [], positive: []}},
{id: "707", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "2", score: 3, examples: {negative: [], positive: []}},
{id: "708", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "3", score: 2, examples: {negative: [], positive: []}},
{id: "709", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "4", score: 1, examples: {negative: [], positive: []}},
{id: "710", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "5", score: 0, examples: {negative: [], positive: []}},
{id: "711", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "6", score: 2, examples: {negative: [], positive: []}},
{id: "712", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "7", score: 3, examples: {negative: [], positive: []}},
{id: "713", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "9", score: 1, examples: {negative: [], positive: []}},
{id: "714", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "12", score: 0, examples: {negative: [], positive: []}},
{id: "715", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "13", score: 2, examples: {negative: [], positive: []}},
{id: "716", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "14", score: 2, examples: {negative: [], positive: []}},
{id: "717", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "16", score: 0, examples: {negative: [], positive: []}},
{id: "718", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "17", score: 1, examples: {negative: [], positive: []}},
{id: "719", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "18", score: 0, examples: {negative: [], positive: []}},
{id: "720", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "19", score: 1, examples: {negative: [], positive: []}},
{id: "721", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "20", score: 1, examples: {negative: [], positive: []}},
{id: "722", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "21", score: 1, examples: {negative: [], positive: []}},
{id: "723", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "22", score: 0, examples: {negative: [], positive: []}},
{id: "724", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "23", score: 0, examples: {negative: [], positive: []}},
{id: "725", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "24", score: 1, examples: {negative: [], positive: []}},
{id: "726", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "25", score: 2, examples: {negative: [], positive: []}},
{id: "727", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "26", score: 0, examples: {negative: [], positive: []}},
{id: "728", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "27", score: 2, examples: {negative: [], positive: []}},
{id: "729", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "28", score: 0, examples: {negative: [], positive: []}},
{id: "730", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "29", score: 1, examples: {negative: [], positive: []}},
{id: "731", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "30", score: 2, examples: {negative: [], positive: []}},
{id: "732", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "31", score: 1, examples: {negative: [], positive: []}},
{id: "733", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "32", score: 0, examples: {negative: [], positive: []}},
{id: "734", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "33", score: 2, examples: {negative: [], positive: []}},
{id: "735", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "34", score: 0, examples: {negative: [], positive: []}},
{id: "736", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "35", score: 0, examples: {negative: [], positive: []}},
{id: "737", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "36", score: 0, examples: {negative: [], positive: []}},
{id: "738", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "37", score: 1, examples: {negative: [], positive: []}},
{id: "739", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "38", score: 0, examples: {negative: [], positive: []}},
{id: "740", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "39", score: 1, examples: {negative: [], positive: []}},
{id: "741", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "40", score: 1, examples: {negative: [], positive: []}},
{id: "742", studentId: "recvRsY2meifvobJA", studentWritingPieceId: "5130", criteriaId: "41", score: 2, examples: {negative: [], positive: []}},
].map(score => {
    const possibleScores = stylus_criteria.find(c => c.id === score.criteriaId)?.possibleScores;
    return {
      ...score,
      score: score.score / (possibleScores?.[possibleScores.length - 1] ?? 1)
    }
});

export default { classes, students, studentWritingPieces, scores }

import React, { useMemo, useState, useEffect } from 'react'
import { Box } from "@mui/material"
import { ConfidenceTable, FilterControls, DetailDialog } from './'
import { calculateConfidence } from '../../utils'
import { Student, KS2Data, KS2Criteria, Topic, StylusCriteriaScore, Level, Strand } from '../../types'

interface KS2TeacherReportContentProps {
  data: KS2Data
  currentDataKey?: string
  canChangeData?: boolean
  selectedDataKeys: string[]
  setSelectedDataKeys: React.Dispatch<React.SetStateAction<string[]>>
}

const DATA_OPTIONS = [
  {value: 'kingsley', label: 'Kingsley'},
  {value: 'wollaston', label: 'Wollaston'},
  {value: 'hall-meadow', label: 'Hall Meadow'},
  {value: 'kettering-park-junior', label: 'Kettering Park Junior'},
  {value: 'little-harrowden', label: 'Little Harrowden'}
]

export default function KS2TeacherReportContent({ 
  data, 
  currentDataKey, 
  canChangeData, 
  selectedDataKeys, 
  setSelectedDataKeys 
}: KS2TeacherReportContentProps) {
  const { levels, ks2_criteria, strands, stylus_criteria, classes, students, writingPieces, scores, studentWritingPieces, topics } = data
  const [selectedClasses, setSelectedClasses] = useState<string[]>(classes.map(c => c.name))
  const [selectedPieces, setSelectedPieces] = useState<string[]>(
    Array.from(new Set(
      studentWritingPieces
        .map(swp => writingPieces.find(wp => wp.id === swp.writingPieceId)?.name)
        .filter((name): name is string => name !== undefined)
    ))
  );
  const [groupBy, setGroupBy] = useState<'ks2Criteria' | 'strands'>('ks2Criteria')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null)
  const [selectedCriteria, setSelectedCriteria] = useState<KS2Criteria | Topic | null>(null)
  const [detailScores, setDetailScores] = useState<StylusCriteriaScore[]>([])
  const [selectedPieceIx, setSelectedPieceIx] = useState<string[]>(
    Array.from(new Set(studentWritingPieces.map(swp => swp.pieceIx?.toString() || '0')))
  );
  const [dialogType, setDialogType] = useState<'student' | 'parent' | 'child' | 'student-child'>('student');
  const [curricItem, setCurricItem] = useState<KS2Criteria | Topic | Level | Strand | null>(null);
  const [curricItemType, setCurricItemType] = useState<CurricItemType>(null);
  const [groupByClass, setGroupByClass] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>();

  type CurricItemType = 'level' | 'strand' | 'ks2Criteria' | 'topic' | null;

  // Reset filters whenever data changes
  useEffect(() => {
    setSelectedClasses(classes.map(c => c.name))
    setSelectedPieces(Array.from(new Set(
      studentWritingPieces
        .map(swp => writingPieces.find(wp => wp.id === swp.writingPieceId)?.name)
        .filter((name): name is string => name !== undefined)
    )))
    setSelectedPieceIx(Array.from(new Set(studentWritingPieces.map(swp => swp.pieceIx?.toString() || '0'))))
    setGroupBy('ks2Criteria')
  }, [data, classes, studentWritingPieces, writingPieces])

  // Reset selected schools when data changes
  useEffect(() => {
    if (currentDataKey) {
      setSelectedDataKeys([currentDataKey]);
    }
  }, [currentDataKey]);

  // Update effect to update URL when selected schools change
  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    )
    const currentData = params.data || ''
    const newData = selectedDataKeys.join(',')
    
    // Only update if the data param has actually changed
    if (currentData !== newData) {
      if (newData) {
        params.data = newData
      } else {
        delete params.data
      }
      const queryString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
      
      window.history.replaceState(
        null, 
        '', 
        queryString ? `?${queryString}` : window.location.pathname
      )
    }
  }, [selectedDataKeys])

  // Filter students based on selected class
  const filteredStudents = useMemo(() => {
    return students.filter(student => 
      selectedClasses.includes(classes.find(c => c.id === student.classId)?.name || '')
    )
  }, [students, selectedClasses, classes])

  const calculateStudentConfidenceData = useMemo(() => {
    return (filteredStudents: Student[], selectedPieces: string[]) => {
      const hasMissingPieceIx = studentWritingPieces.some(swp => swp.pieceIx === undefined);

      return filteredStudents.map(student => {
        const ks2ConfidenceData: { [ks2: string]: { negative: number, positive: number, averageScore: number | null } } = {};
        const topicConfidenceData: { [topic: string]: { negative: number, positive: number, averageScore: number | null } } = {};

        ks2_criteria.forEach(ks2 => {
          const relevantScores = scores.filter(score => {
            const studentWritingPiece = studentWritingPieces.find(swp => swp.id === score.studentWritingPieceId);
            const writingPiece = writingPieces.find(wp => wp.id === studentWritingPiece?.writingPieceId);
            return score.studentId === student.id &&
              stylus_criteria.find(sc => sc.id === score.criteriaId && sc.ks2CriteriaId === ks2.id) &&
              selectedPieces.includes(writingPiece?.name || '') &&
              (hasMissingPieceIx || (studentWritingPiece?.pieceIx !== undefined && 
                selectedPieceIx.includes(studentWritingPiece.pieceIx.toString())));
          });
          ks2ConfidenceData[ks2.id] = calculateConfidence(relevantScores);
        });

        topics.forEach(topic => {
          const relevantScores = scores.filter(score => {
            const studentWritingPiece = studentWritingPieces.find(swp => swp.id === score.studentWritingPieceId);
            const writingPiece = writingPieces.find(wp => wp.id === studentWritingPiece?.writingPieceId);
            return score.studentId === student.id &&
              stylus_criteria.find(sc => sc.id === score.criteriaId && sc.topicId === topic.id) &&
              selectedPieces.includes(writingPiece?.name || '') &&
              (hasMissingPieceIx || (studentWritingPiece?.pieceIx !== undefined && 
                selectedPieceIx.includes(studentWritingPiece.pieceIx.toString())));
          });
          topicConfidenceData[topic.id] = calculateConfidence(relevantScores);
        });

        return { student, ks2ConfidenceData, topicConfidenceData };
      });
    };
  }, [ks2_criteria, strands, stylus_criteria, scores, studentWritingPieces, writingPieces, selectedPieceIx]);

  const studentConfidenceData = useMemo(() => {
    return calculateStudentConfidenceData(filteredStudents, selectedPieces);
  }, [calculateStudentConfidenceData, filteredStudents, selectedPieces]);

  const ks2Data = useMemo(() => {
    const table: { [level: string]: { [ks2: string]: { [student: string]: { negative: number, positive: number, averageScore: number | null } } } } = {};

    levels.forEach(level => {
      table[level.id] = {};
      ks2_criteria.filter(ks2 => ks2.levelId === level.id).forEach(ks2 => {
        table[level.id][ks2.id] = {};
        studentConfidenceData.forEach(({ student, ks2ConfidenceData }) => {
          table[level.id][ks2.id][student.id] = ks2ConfidenceData[ks2.id];
        });
      });
    });

    return table;
  }, [levels, ks2_criteria, studentConfidenceData]);

  const strandData = useMemo(() => {
    const table: { [strand: string]: { [topic: string]: { [student: string]: { negative: number, positive: number, averageScore: number | null } } } } = {};

    strands.forEach(strand => {
      table[strand.id] = {};
      topics.filter(topic => topic.strandId === strand.id).forEach(topic => {
        table[strand.id][topic.id] = {};
        studentConfidenceData.forEach(({ student, topicConfidenceData }) => {
          table[strand.id][topic.id][student.id] = topicConfidenceData[topic.id];
        });
      });
    });

    return table;
  }, [strands, topics, studentConfidenceData]);

  const handleCellClick = (
    student: Student | null, 
    groupId: string | null, 
    type: 'student' | 'parent' | 'child' | 'student-child',
    classId?: string
  ) => {
    setSelectedClassId(classId);
    const hasMissingPieceIx = studentWritingPieces.some(swp => swp.pieceIx === undefined);

    let relevantScores = scores.filter(score => {
      const studentWritingPiece = studentWritingPieces.find(swp => swp.id === score.studentWritingPieceId);
      const writingPiece = writingPieces.find(wp => wp.id === studentWritingPiece?.writingPieceId);
      const matchesPiece = selectedPieces.includes(writingPiece?.name || '') &&
        (hasMissingPieceIx || (studentWritingPiece?.pieceIx !== undefined && 
          selectedPieceIx.includes(studentWritingPiece.pieceIx.toString())));

      if (!matchesPiece) return false;

      // Get the student's class
      const studentClass = students.find(s => s.id === score.studentId)?.classId;
      const matchesClass = classId ? studentClass === classId : true;

      if (!matchesClass) return false;

      const criteria = stylus_criteria.find(sc => sc.id === score.criteriaId);
      const childId = groupBy === 'ks2Criteria' ? criteria?.ks2CriteriaId : criteria?.topicId;
      const child = childId ? (groupBy === 'ks2Criteria' ? ks2_criteria.find(c => c.id === childId) : topics.find(t => t.id === childId)) : null;

      // For class cell clicks, filter by both class and criteria
      if (classId && type === 'student-child') {
        return childId === groupId && matchesClass;
      }

      // For class header clicks, only filter by class
      if (classId && type === 'parent') {
        return matchesClass;
      }

      switch (type) {
        case 'student':
          return score.studentId === student?.id;
        case 'parent':
          return groupBy === 'ks2Criteria'
            ? child && 'levelId' in child && child.levelId === groupId
            : child && 'strandId' in child && child.strandId === groupId;
        case 'child':
        case 'student-child':
          return stylus_criteria.some(sc => 
            sc.id === score.criteriaId && (
              groupBy === 'ks2Criteria' 
                ? sc.ks2CriteriaId === groupId 
                : sc.topicId === groupId
            )
          );
      }
    });

    // Average scores by criteria when showing class data
    if (classId) {
      const scoresByCriteria: { [criteriaId: string]: number[] } = {};
      
      relevantScores.forEach(score => {
        if (!scoresByCriteria[score.criteriaId]) {
          scoresByCriteria[score.criteriaId] = [];
        }
        scoresByCriteria[score.criteriaId].push(score.score ?? 0);
      });

      relevantScores = Object.entries(scoresByCriteria).map(([criteriaId, scores]) => ({
        id: `avg-${criteriaId}`,
        criteriaId,
        studentId: '',
        studentWritingPieceId: '',
        score: scores.reduce((a, b) => a + b, 0) / scores.length,
        examples: { negative: [], positive: [] }
      }));
    }

    let newCurricItemType: CurricItemType = null;
    const curricItem = type === 'parent' 
      ? (groupBy === 'ks2Criteria' 
          ? (newCurricItemType = 'level', levels.find(l => l.id === groupId) || null)
          : (newCurricItemType = 'strand', strands.find(s => s.id === groupId) || null))
      : type === 'child' || type === 'student-child'
        ? (groupBy === 'ks2Criteria'
            ? (newCurricItemType = 'ks2Criteria', ks2_criteria.find(c => c.id === groupId) || null)
            : (newCurricItemType = 'topic', topics.find(t => t.id === groupId) || null))
        : null;

    setSelectedStudent(type === 'student' ? student : type === 'parent' || type === 'child' ? null : student);
    setDetailScores(relevantScores);
    setDialogType(type);
    setDialogOpen(true);
    setCurricItem(curricItem);
    setCurricItemType(newCurricItemType);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedStudent(null)
    setSelectedCriteria(null)
  }

  return (
    <Box sx={{ 
      padding: 8, 
      '& > *:not(:last-child)': { marginBottom: 8 },
      maxWidth: '1600px',
      margin: '0 auto',
    }}>
      <FilterControls
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
        classes={classes}
        selectedPieces={selectedPieces}
        setSelectedPieces={setSelectedPieces}
        writingPieces={writingPieces}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        selectedPieceIx={selectedPieceIx}
        setSelectedPieceIx={setSelectedPieceIx}
        studentWritingPieces={studentWritingPieces}
        currentDataKey={currentDataKey}
        groupByClass={groupByClass}
        setGroupByClass={setGroupByClass}
        selectedDataKeys={selectedDataKeys}
        setSelectedDataKeys={setSelectedDataKeys}
        canChangeData={canChangeData || false}
      />

      <ConfidenceTable
        title={groupBy === 'ks2Criteria' ? "KS2 Criteria" : "Strands"}
        data={groupBy === 'ks2Criteria' ? ks2Data : strandData}
        filteredStudents={filteredStudents}
        handleCellClick={handleCellClick}
        referenceData={data}
        groupByClass={groupByClass}
        classes={classes}
        selectedClasses={selectedClasses}
      />

      <DetailDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        student={selectedStudent}
        curricItem={curricItem}
        stylusCriteria={stylus_criteria}
        scores={detailScores}
        studentWritingPieces={studentWritingPieces}
        writingPieces={writingPieces}
        groupBy={groupBy}
        students={students}
        type={dialogType}
        curricItemType={curricItemType}
        selectedClassId={selectedClassId}
        className={classes.find(c => c.id === selectedClassId)?.name}
      />
    </Box>
  )
}

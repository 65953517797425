import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Box, IconButton } from '@mui/material';
import { ArrowBack, OpenInNew, NavigateBefore, NavigateNext } from '@mui/icons-material';
import ScanDisplay from './ScanDisplay';
import { Scan } from '../types';

interface BaseDialogOnePageProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  topSectionItems?: {
    label: string;
    value: string | number | React.ReactNode;
  }[];
  scans?: Scan[][];
}

const TopSection = ({ items }: { items: { label: string; value: string | number | React.ReactNode }[] }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', gap: 2, mb: 2 }}>
    {items.map((item, index) => (
      <Box 
        key={index} 
        sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'flex-start', 
          bgcolor: 'primary.main',
          color: 'white',
          borderRadius: '4px', 
          padding: 2, 
          height: '100%' 
        }}
      >
        <Typography variant="caption" sx={{ color: 'white', mb: 0.5, fontWeight: 600 }}>{item.label}</Typography>
        {typeof item.value === 'string' || typeof item.value === 'number' ? (
          <Typography variant="body1" sx={{ color: 'white', fontWeight: 600 }}>{item.value}</Typography>
        ) : (
          item.value
        )}
      </Box>
    ))}
  </Box>
);

export default function BaseDialogOnePage({
  open,
  onClose,
  children,
  maxWidth = 'md',
  fullWidth = true,
  topSectionItems = [],
  scans,
}: BaseDialogOnePageProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentScanGroup, setCurrentScanGroup] = useState(0);
  const totalPages = scans?.[currentScanGroup]?.length ?? 0;

  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
  const handlePreviousScanGroup = () => setCurrentScanGroup(prev => Math.max(prev - 1, 0));
  const handleNextScanGroup = () => setCurrentScanGroup(prev => Math.min(prev + 1, (scans?.length ?? 1) - 1));

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogContent>
        {topSectionItems.length > 0 && <TopSection items={topSectionItems} />}
        <Box sx={{ display: 'flex', gap: 2, height: '100%' }}>
          <Box sx={{ flex: 2, pr: 2, borderRight: '1px solid rgba(0, 0, 0, 0.12)', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Scans</Typography>
              <Box sx={{ ml: 'auto', display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton onClick={handlePreviousScanGroup} disabled={currentScanGroup === 0}>
                  <NavigateBefore />
                </IconButton>
                <Typography>{`Piece ${currentScanGroup + 1}/${scans?.length ?? 1}`}</Typography>
                <IconButton onClick={handleNextScanGroup} disabled={currentScanGroup === (scans?.length ?? 1) - 1}>
                  <NavigateNext />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ flex: '1 1 auto' }}>
              <ScanDisplay
                currentPage={currentPage}
                totalPages={totalPages}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
                scans={scans?.[currentScanGroup]}
              />
            </Box>
          </Box>
          <Box sx={{ flex: 3, overflow: 'auto', pl: 2, maxHeight: '700px' }}>{children}</Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

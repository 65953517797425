import { Class, Student, StylusCriteriaScore, StudentWritingPiece} from "../types";
import { writingPieces, stylus_criteria } from "./ks2-data";

export default function generateMockData() {
    const classes: Class[] = [
        { id: "1", name: "Class A" },
        { id: "2", name: "Class B" },
        { id: "3", name: "Class C" },
        { id: "4", name: "Class D" }
    ];
    const students: Student[] = [];
    const scores: StylusCriteriaScore[] = [];
    const studentWritingPieces: StudentWritingPiece[] = [];

    let counters = {studentWritingPieceId: 1, stylusCriteriaScoreId: 1};
    for (let i = 0; i < 96; i++) {
        const studentId = (i + 1).toString();
        const classId = ((i % classes.length) + 1).toString();
        students.push({
            id: studentId,
            name: `Student ${studentId}`,
            classId: classId
        });

        const numPieces = Math.floor(Math.random() * 5) + 1;
        const selectedPieces = shuffle(writingPieces).slice(0, numPieces);

        selectedPieces.forEach((piece) => {
            const count = 1 // Math.random() < 0.75 ? 1 : 2;
            for (let j = 0; j < count; j++) {
                const studentWritingPieceId = counters.studentWritingPieceId.toString();
                studentWritingPieces.push({
                    id: studentWritingPieceId,
                    studentId: studentId,
                    writingPieceId: piece.id,
                    scans: [...new Array(Math.floor(Math.random() * 3) + 1)].map((_, ix) => ({ page: ix + 1, url: "" }))
                });

                const selectedCriteria = stylus_criteria.filter(criteria => piece.stylusCriteriaIds.includes(criteria.id));

                selectedCriteria.forEach(criteria => {
                    scores.push({
                        id: counters.stylusCriteriaScoreId.toString(),
                        studentId: studentId,
                        studentWritingPieceId: studentWritingPieceId,
                        criteriaId: criteria.id,
                        score: criteria.possibleScores[Math.floor(Math.random() * criteria.possibleScores.length)] / criteria.possibleScores[criteria.possibleScores.length - 1],
                        examples: {
                            negative: Array.from({ length: Math.floor(Math.random() * 3) }, () =>
                                `An excerpt from student's ${piece.name.toLowerCase()} that provides negative evidence`
                            ),
                            positive: Array.from({ length: Math.floor(Math.random() * 3) }, () =>
                                `An excerpt from student's ${piece.name.toLowerCase()} that provides positive evidence`
                            )
                        }
                    });
                    counters.stylusCriteriaScoreId++;
                });

                counters.studentWritingPieceId++;
            }
        });
    }

    function shuffle<T>(array: T[]): T[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    return {
        classes,
        studentWritingPieces,
        students,
        scores,
    };
}
import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from "@mui/material";
import ConfidenceCell from './ConfidenceCell';
import { Student, Level, KS2Criteria, Strand, Topic, KS2Data } from '../../types';
import { alpha, Theme } from '@mui/material/styles';

interface ConfidenceTableProps {
  title: string;
  data: Record<string, Record<string, Record<string, { negative: number; positive: number; averageScore: number | null }>>>;
  filteredStudents: Student[];
  handleCellClick: (student: Student | null, criteria: string | null, type: 'student' | 'parent' | 'child' | 'student-child', classId?: string) => void;
  referenceData: KS2Data;
  groupByClass: boolean;
  classes: { id: string; name: string; schoolName?: string }[];
  selectedClasses: string[];
}

const tableStyles = {
  borderSpacing: 0,
  borderCollapse: 'separate',
  '& th, & td': {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: 'none',
    borderTop: 'none',
  },
  '& th': {
    backgroundColor: 'background.paper',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  '& tr th:first-of-type, & tr td:first-of-type': {
    borderLeft: '1px solid rgba(255, 255, 255, 1)',
  },
};

const STICKY_CELL_WIDTH = 300;

const stickyCellStyle = (left: number) => ({
  position: 'sticky',
  left,
  zIndex: 2,
  backgroundColor: 'background.paper',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  width: STICKY_CELL_WIDTH,
  minWidth: STICKY_CELL_WIDTH,
  boxSizing: 'border-box',
});

const clickableCellStyle = (theme: Theme) => ({
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.default, 0.96),
  },
});

const studentHeaderCellStyle = {
  width: 'auto',
  minWidth: 120,
  flex: 1,
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  paddingBottom: '24px'
};

const ConfidenceTable = ({
  title,
  data,
  filteredStudents,
  handleCellClick,
  referenceData,
  groupByClass,
  classes,
  selectedClasses,
}: ConfidenceTableProps) => {
  const isKS2Criteria = title === "KS2 Criteria";

  // Add this to check for multiple schools
  const uniqueSchools = new Set(classes.map(c => c.schoolName)).size;

  // Filter classes based on selectedClasses
  const filteredClasses = useMemo(() => {
    if (!groupByClass) return classes;
    return classes.filter(c => selectedClasses.includes(c.name));
  }, [classes, selectedClasses, groupByClass]);

  // Calculate class-level data
  const classData = useMemo(() => {
    if (!groupByClass) return null;

    const result: Record<string, Record<string, Record<string, { negative: number; positive: number; averageScore: number | null }>>> = {};
    
    // Initialize the structure
    Object.keys(data).forEach(parentId => {
      result[parentId] = {};
      Object.keys(data[parentId]).forEach(childId => {
        result[parentId][childId] = {};
        classes.forEach(cls => {
          result[parentId][childId][cls.id] = { negative: 0, positive: 0, averageScore: null };
        });
      });
    });

    // Calculate class averages
    Object.entries(data).forEach(([parentId, parentValue]) => {
      Object.entries(parentValue).forEach(([childId, childValue]) => {
        const scoresByClass: Record<string, number[]> = {};
        
        Object.entries(childValue).forEach(([studentId, scores]) => {
          const student = filteredStudents.find(s => s.id.toString() === studentId);
          if (student && scores.averageScore !== null) {
            const classId = student.classId;
            if (!scoresByClass[classId]) scoresByClass[classId] = [];
            scoresByClass[classId].push(scores.averageScore);
          }
        });

        // Calculate average for each class
        Object.entries(scoresByClass).forEach(([classId, scores]) => {
          if (scores.length > 0) {
            const average = scores.reduce((a, b) => a + b, 0) / scores.length;
            result[parentId][childId][classId] = {
              negative: 0, // These could be calculated if needed
              positive: 0,
              averageScore: average
            };
          }
        });
      });
    });

    return result;
  }, [data, filteredStudents, classes, groupByClass]);

  const displayData = groupByClass ? classData : data;
  const columns = groupByClass ? filteredClasses : filteredStudents;

  const getNameById = (type: 'level' | 'ks2Criteria' | 'strand' | 'topic', id: string): string => {
    switch (type) {
      case 'level':
        return referenceData.levels.find(level => level.id.toString() === id)?.name || id;
      case 'ks2Criteria':
        return referenceData.ks2_criteria.find(criteria => criteria.id.toString() === id)?.name || id;
      case 'strand':
        return referenceData.strands.find(strand => strand.id.toString() === id)?.name || id;
      case 'topic':
        return referenceData.topics.find(topic => topic.id.toString() === id)?.name || id;
      default:
        return id;
    }
  };

  const isCellClickable = (averageScore: number | null) => averageScore !== null;

  const renderEmptyState = () => (
    <Box
      sx={{
        position: 'absolute',
        top: 58,
        left: 400,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 10,
      }}
    >
      <Typography variant="h6">No students available</Typography>
      <Typography variant="body1">Please add students or adjust your filters.</Typography>
    </Box>
  );

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2, marginBottom: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {groupByClass 
            ? `${filteredClasses.length} ${filteredClasses.length === 1 ? 'class' : 'classes'}`
            : `${filteredStudents.length} ${filteredStudents.length === 1 ? 'student' : 'students'}`}
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', overflowX: 'auto', border: '1px solid', borderRadius: '8px' }}>
        <Table sx={{ ...tableStyles, tableLayout: 'auto', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={(theme) => ({ ...stickyCellStyle(0) })}>
                {isKS2Criteria ? "Level" : "Strand"}
              </TableCell>
              <TableCell sx={(theme) => ({ ...stickyCellStyle(STICKY_CELL_WIDTH) })}>
                {isKS2Criteria ? "KS2 Criteria" : "Topic"}
              </TableCell>
              {columns.map(item => (
                <TableCell
                  key={item.id}
                  sx={studentHeaderCellStyle}
                  onClick={() => handleCellClick(
                    groupByClass ? null : item as Student, 
                    null, 
                    groupByClass ? 'parent' : 'student',
                    groupByClass ? item.id : undefined
                  )}
                >
                  {item.name}
                  {item.schoolName && uniqueSchools > 1 && (
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                      {item.schoolName}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(displayData || {}).map(([parentId, parentValue]) => (
              Object.entries(parentValue).map(([childId, childValue], index) => (
                <TableRow key={`${parentId}-${childId}`}>
                  {index === 0 && (
                    <TableCell 
                      sx={(theme) => ({ ...stickyCellStyle(0), ...clickableCellStyle(theme) })}
                      rowSpan={Object.keys(parentValue).length}
                      onClick={() => handleCellClick(null, parentId, 'parent')}
                    >
                      {getNameById(isKS2Criteria ? 'level' : 'strand', parentId)}
                    </TableCell>
                  )}
                  <TableCell 
                    sx={(theme) => ({ ...stickyCellStyle(STICKY_CELL_WIDTH), ...clickableCellStyle(theme) })}
                    onClick={() => handleCellClick(null, childId, 'child')}
                  >
                    {getNameById(isKS2Criteria ? 'ks2Criteria' : 'topic', childId)}
                  </TableCell>
                  {columns.map(item => {
                    const cellData = childValue[item.id.toString()];
                    const isClickable = isCellClickable(cellData?.averageScore);
                    return (
                      <ConfidenceCell
                        key={`${parentId}-${childId}${groupByClass ? '' : `-${childId}`}-${item.id}`}
                        cellData={cellData}
                        isClickable={isClickable}
                        handleCellClick={() => {
                          if (!isClickable) return;
                          
                          if (groupByClass) {
                            handleCellClick(null, childId, 'student-child', item.id);
                          } else {
                            handleCellClick(item as Student, childId, 'student-child');
                          }
                        }}
                      />
                    );
                  })}
                </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
        {columns.length === 0 && renderEmptyState()}
      </Box>
    </div>
  );
};

export default React.memo(ConfidenceTable);

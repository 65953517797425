import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import ImageIcon from '@mui/icons-material/Image';
import Header from '../components/Header';

const prototypes = [
  { id: 'ks2-teacher-report', name: 'KS2 Teacher Report', path: '/ks2-teacher-report', Icon: TableViewIcon },
  { id: 'scan-viewer', name: 'Scan Viewer', path: '/scan-viewer', Icon: ImageIcon },
];

const dataOptions = [
  { value: 'mock', label: 'Mock Data' },
  { value: 'kingsley', label: 'Kingsley Primary' },
  { value: 'wollaston', label: 'Wollaston Primary' },
  { value: 'reepham', label: 'Reepham Primary' },
  { value: 'hall-meadow', label: 'Hall Meadow Primary' },
  { value: 'kettering-park-junior', label: 'Kettering Park Junior' },
  { value: 'little-harrowden', label: 'Little Harrowden' },
  { value: 'inmat', label: 'All Inmat Schools' },
];

function Home() {
  const [selectedData, setSelectedData] = useState('mock');

  return (
    <>
      <Header />
      <Grid container spacing={3} sx={{ padding: 3 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="data-select-label">Select Data Set</InputLabel>
            <Select
              labelId="data-select-label"
              value={selectedData}
              label="Select Data Set"
              onChange={(e) => setSelectedData(e.target.value)}
            >
              {dataOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {prototypes.map((prototype) => {
          const IconComponent = prototype.Icon;
          return (
            <Grid item xs={12} sm={6} md={4} key={prototype.id}>
              <Card 
                component={Link} 
                to={`${prototype.path}${selectedData ? selectedData === 'inmat' ? '?data=kettering-park-junior&inmat-admin=true' : `?data=${selectedData}` : ''}`}
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  textDecoration: 'none',
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <IconComponent sx={{ fontSize: 60, mb: 2, color: 'primary.main' }} />
                  <Typography variant="h6" component="h2" gutterBottom>
                    {prototype.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default Home;

import React, { useCallback } from 'react';
import { Box, Select, MenuItem, TextField, FormControlLabel, Switch, Chip, Button, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { WritingPiece, Class, StudentWritingPiece } from '../../types';
import ChipFilter from '../ChipFilter';

interface FilterControlsProps {
  selectedClasses: string[];
  setSelectedClasses: React.Dispatch<React.SetStateAction<string[]>>;
  classes: Class[];
  selectedPieces: string[];
  setSelectedPieces: React.Dispatch<React.SetStateAction<string[]>>;
  writingPieces: WritingPiece[];
  groupBy: 'ks2Criteria' | 'strands';
  setGroupBy: React.Dispatch<React.SetStateAction<'ks2Criteria' | 'strands'>>;
  selectedPieceIx: string[];
  setSelectedPieceIx: React.Dispatch<React.SetStateAction<string[]>>;
  studentWritingPieces: StudentWritingPiece[];
  onDataChange?: (newDataKey: string) => void;
  currentDataKey?: string;
  groupByClass: boolean;
  setGroupByClass: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDataKeys: string[];
  setSelectedDataKeys: React.Dispatch<React.SetStateAction<string[]>>;
  canChangeData: boolean;
}

const DATA_OPTIONS = [
  { value: 'kingsley', label: 'Kingsley' },
  { value: 'wollaston', label: 'Wollaston' },
  { value: 'hall-meadow', label: 'Hall Meadow' },
  { value: 'kettering-park-junior', label: 'Kettering Park Junior' },
  { value: 'little-harrowden', label: 'Little Harrowden' },
] as const;

const FilterControls = React.memo(({
  selectedClasses,
  setSelectedClasses,
  classes,
  selectedPieces,
  setSelectedPieces,
  writingPieces,
  groupBy,
  setGroupBy,
  selectedPieceIx,
  setSelectedPieceIx,
  studentWritingPieces,
  groupByClass,
  setGroupByClass,
  selectedDataKeys,
  setSelectedDataKeys,
  canChangeData,
}: FilterControlsProps) => {
  const handlePieceToggle = useCallback((piece: string) => () => {
    setSelectedPieces((prevSelectedPieces: string[]) => {
      if (prevSelectedPieces.includes(piece)) {
        return prevSelectedPieces.filter((p) => p !== piece);
      } else {
        return [...prevSelectedPieces, piece];
      }
    });
  }, [setSelectedPieces]);

  const handleSelectAll = useCallback(() => {
    setSelectedPieces(writingPieces.map(piece => piece.name));
  }, [writingPieces, setSelectedPieces]);

  const handleClearSelection = useCallback(() => {
    setSelectedPieces([]);
  }, [setSelectedPieces]);

  const handleClassToggle = useCallback((className: string) => () => {
    setSelectedClasses((prevSelectedClasses: string[]) => {
      if (prevSelectedClasses.includes(className)) {
        return prevSelectedClasses.filter((c) => c !== className);
      } else {
        return [...prevSelectedClasses, className];
      }
    });
  }, [setSelectedClasses]);

  const handleSelectAllClasses = useCallback(() => {
    setSelectedClasses(classes.map(c => c.name));
  }, [classes, setSelectedClasses]);
  const handleClearClassSelection = useCallback(() => {
    setSelectedClasses([]);
  }, [setSelectedClasses]);

  const handleGroupByChange = (
    event: React.MouseEvent<HTMLElement>,
    newGroupBy: 'ks2Criteria' | 'strands' | null,
  ) => {
    if (newGroupBy !== null) {
      setGroupBy(newGroupBy);
    }
  };

  const selectedPieceNames = selectedPieces;
  const selectedClassNames = selectedClasses;

  const handlePieceIxToggle = useCallback((pieceIx: string) => () => {
    setSelectedPieceIx(prev => {
      if (prev.includes(pieceIx)) {
        return prev.filter(p => p !== pieceIx);
      }
      return [...prev, pieceIx].sort();
    });
  }, [setSelectedPieceIx]);

  const maxPieceIx = Math.max(...studentWritingPieces.map(p => Number(p.pieceIx) ?? 0));
  const pieceNumbers = Array.from({ length: maxPieceIx + 1 }, (_, i) => ({
    id: i.toString(),
    label: `Piece ${i + 1}`
  }));

  const disabledWritingPieces = writingPieces
    .filter(piece => !studentWritingPieces.some(swp => swp.writingPieceId === piece.id))
    .map(p => p.name);

  const showPieceNumberFilter = studentWritingPieces.every(p => p.pieceIx !== undefined);

  const handleDataKeyToggle = useCallback((dataKey: string) => () => {
    setSelectedDataKeys(prev => {
      if (prev.includes(dataKey)) {
        if (prev.length <= 1) return prev;
        return prev.filter(k => k !== dataKey);
      }
      return [...prev, dataKey];
    });
  }, [setSelectedDataKeys]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 4, maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'flex-start', rowGap: 0 }}>
        {canChangeData && (
          <ChipFilter
            label="Schools"
            items={DATA_OPTIONS.map(opt => ({ id: opt.value, label: opt.label }))}
            selectedItems={selectedDataKeys}
            onToggle={(id) => handleDataKeyToggle(id)()}
            onSelectAll={() => setSelectedDataKeys(DATA_OPTIONS.map(opt => opt.value))}
            onClearSelection={() => setSelectedDataKeys([])}
            preventClearAll
            sx={{ width: '300px' }}
          />
        )}

        <ChipFilter
          label="Classes"
          items={classes.map(c => ({ id: c.name, label: c.name }))}
          selectedItems={selectedClassNames}
          onToggle={(id) => handleClassToggle(id)()}
          onSelectAll={handleSelectAllClasses}
          onClearSelection={handleClearClassSelection}
          sx={{ width: '280px' }}
        />

        <ChipFilter
          label="Writing Piece Type"
          items={writingPieces.map(p => ({ id: p.name, label: p.name }))}
          selectedItems={selectedPieceNames}
          disabledItems={disabledWritingPieces}
          onToggle={(id) => handlePieceToggle(id)()}
          onSelectAll={handleSelectAll}
          onClearSelection={handleClearSelection}
          sx={{ width: '300px' }}
        />

        {showPieceNumberFilter && (
          <ChipFilter
            label="Writing Piece Number"
            items={pieceNumbers}
            selectedItems={selectedPieceIx}
            onToggle={(id) => handlePieceIxToggle(id)()}
            onSelectAll={() => setSelectedPieceIx(pieceNumbers.map(p => p.id))}
            onClearSelection={() => setSelectedPieceIx([])}
            sx={{ width: '300px' }}
          />
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <label>Curriculum Lens</label>
          <ToggleButtonGroup
            value={groupBy}
            exclusive
            onChange={handleGroupByChange}
            aria-label="curriculum lens"
            size="small"
          >
            <ToggleButton value="ks2Criteria" aria-label="KS2 Criteria">
              KS2 Criteria
            </ToggleButton>
            <ToggleButton value="strands" aria-label="Strands">
              Strands
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <label>Mode</label>
          <ToggleButtonGroup
            value={groupByClass ? 'class' : 'student'}
            exclusive
            onChange={(_, newValue) => setGroupByClass(newValue === 'class')}
            aria-label="mode"
            size="small"
          >
            <ToggleButton value="student" aria-label="Student">
              Student
            </ToggleButton>
            <ToggleButton value="class" aria-label="Class">
              Class
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Box>
  );
});

export default FilterControls;

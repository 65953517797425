import { KS2Data } from '../types'
import { strands, topics, levels, ks2_criteria, stylus_criteria, writingPieces } from './ks2-data'
import generateMockData from './generateMockData'
import kingsley from './kingsley'
import wollaston from './wollaston'
import reepham from './reepham'
import hallMeadow from './hall-meadow'
import ketteringParkJunior from './kettering-park-junior'
import littleHarrowden from './little-harrowden'

const staticData = { strands, topics, levels, ks2_criteria, stylus_criteria, writingPieces }

export const mockData: KS2Data = { ...staticData, ...generateMockData() }
export const kingsleyData: KS2Data = { ...staticData, ...kingsley }
export const wollastonData: KS2Data = { ...staticData, ...wollaston }
export const reephamData: KS2Data = { ...staticData, ...reepham }
export const hallMeadowData: KS2Data = { ...staticData, ...hallMeadow }
export const ketteringParkJuniorData: KS2Data = { ...staticData, ...ketteringParkJunior }
export const littleHarrowdenData: KS2Data = { ...staticData, ...littleHarrowden }
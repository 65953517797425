import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box } from '@mui/material';
import { KS2Data } from '../../types';

interface SimpleScore {
  levelName: string;
  criteriaName: string;
  score: number | string;
}

interface ScoresDialogProps {
  open: boolean;
  onClose: () => void;
  studentName: string;
  writingPieceName: string;
  scores: SimpleScore[];
  ks2Data: KS2Data;
}

const ScoresDialog: React.FC<ScoresDialogProps> = ({ open, onClose, studentName, writingPieceName, scores, ks2Data }) => {
  const sortedScores = React.useMemo(() => {
    const levelOrder = ks2Data.levels.reduce((acc, level, index) => {
      acc[level.name] = index;
      return acc;
    }, {} as Record<string, number>);

    const criteriaOrder = ks2Data.ks2_criteria.reduce((acc, criteria, index) => {
      acc[criteria.name] = index;
      return acc;
    }, {} as Record<string, number>);

    return [...scores].sort((a, b) => {
      const levelA = levelOrder[a.levelName] ?? Infinity;
      const levelB = levelOrder[b.levelName] ?? Infinity;
      const levelDiff = levelA - levelB;
      if (levelDiff !== 0) return levelDiff;
      
      const criteriaA = criteriaOrder[a.criteriaName] ?? Infinity;
      const criteriaB = criteriaOrder[b.criteriaName] ?? Infinity;
      return criteriaA - criteriaB;
    });
  }, [scores, ks2Data]);

  const groupedScores = React.useMemo(() => {
    const grouped: Record<string, SimpleScore[]> = {};

    ks2Data.levels.forEach(level => {
      grouped[level.name] = ks2Data.ks2_criteria
        .filter(criteria => criteria.levelId === level.id)
        .map(criteria => ({
          levelName: level.name,
          criteriaName: criteria.name,
          score: '-'
        }));
    });

    sortedScores.forEach(score => {
      const levelScores = grouped[score.levelName];
      if (levelScores) {
        const criteriaIndex = levelScores.findIndex(s => s.criteriaName === score.criteriaName);
        if (criteriaIndex !== -1) {
          levelScores[criteriaIndex].score = score.score !== null ? score.score : '-';
        }
      }
    });

    return grouped;
  }, [ks2Data, sortedScores]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{`Scores for ${studentName} - ${writingPieceName}`}</DialogTitle>
      <DialogContent>
        {ks2Data.levels.map((level) => (
          <Box key={level.id} sx={{ mb: 2 }}>
            <Typography variant="h6">{level.name}</Typography>
            {groupedScores[level.name]?.map(score => (
              <Box key={score.criteriaName} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{score.criteriaName}</Typography>
                <Typography>{typeof score.score === 'number' ? score.score.toFixed(2) : score.score}</Typography>
              </Box>
            ))}
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ScoresDialog;

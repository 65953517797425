import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import KS2TeacherReport from './pages/KS2TeacherReport';
import ScanViewer from './pages/ScanViewer';
import Hotjar from '@hotjar/browser';

const siteId = 5202957;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function App() {
  return (
    <Router>
      <div className="app">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ks2-teacher-report" element={<KS2TeacherReport />} />
            <Route path="/scan-viewer" element={<ScanViewer />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;